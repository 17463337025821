import React from 'react'
import styled from 'styled-components'

export function ErrorBlock({ children }) {
  return (
    <div className="invalid-feedback" style={{ display: 'block' }}>
      {children}
    </div>
  )
}

export function WarningBlock({ children }) {
  return <div className="text-warning">{children}</div>
}

const StyledBorder = styled.div`
  ${(props) =>
    props.hasError ? 'border: 1px solid #dc3545; padding: 12px' : null};
`

export function ErrorWrapper({ children, hasError, error, warning }) {
  if (typeof error === 'object') {
    error = Object.entries(error)
      .map((entry) => entry.join(': '))
      .join('\n')
  }
  return (
    <>
      {(error && <ErrorBlock>{error}</ErrorBlock>) ||
        (warning && <WarningBlock>{warning}</WarningBlock>) ||
        null}
      <StyledBorder hasError={hasError}>{children}</StyledBorder>
    </>
  )
}
