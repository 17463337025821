import { get as getSafe } from 'lodash'
import React from 'react'

import { required } from '../../../utils/validationUtils'
import FormField from '../FormField'
import { renderInput, renderRichTextArea } from '../renderers'
import withFormCard from './withFormCard'

function Description({ initialValues }) {
  return (
    <>
      <FormField
        component={renderInput}
        label="Titel"
        name="translations[0].title"
        type="text"
        validate={[required]}
        helpText="Gebruik een korte pakkende titel voor je vacature. Onderzoek leert dat een titel van 7 tot 14 woorden het beste werkt. De sollicitant scant een titel vaak op functietype en wetenschappelijke discipline, zorg dat je die in elk geval noemt."
      />
      <FormField
        optional
        isOpen={!!getSafe(initialValues, 'translations[0].teaser')}
        component={renderInput}
        label="Teaser"
        name="translations[0].teaser"
        type="textarea"
        helpText="Schrijf een pakkende boodschap (max. 200 karakters) die uitnodigt om door te klikken en/of verder te lezen. De tekst toont onder de titel (zoekresultaat en vacature detailpagina) en op social media."
      />
      <FormField
        component={renderRichTextArea}
        label="Functieomschrijving"
        name="translations[0].description"
        type="textarea"
        maxlength="1000"
        helpText="Maak de functieomschrijving niet te lang. Ons advies: maximaal 200 woorden."
      />
      <FormField
        optional
        isOpen={!!getSafe(initialValues, 'translations[0].embed_url')}
        component={renderInput}
        label="Afbeelding of video (wordt getoond onder de functieomschrijving)"
        name="translations[0].embed_url"
        type="url"
        helpText="Plak een link naar de afbeelding of video (bijv. https://www.youtube.com/watch?v=XNCSTVOcSq8)"
      />
    </>
  )
}

export default withFormCard(Description, {
  label: 'Omschrijving',
  htmlId: 'description_header',
  fields: ['title', 'teaser', 'description'],
})
