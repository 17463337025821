import 'react-widgets/dist/css/react-widgets.css'

import React from 'react'
import { DateTimePicker as BaseDateTimePicker } from 'react-widgets'
import styled from 'styled-components'

import { ErrorBlock, WarningBlock } from './ErrorWrapper'
import { parseDate } from '../../utils/dateUtils'

const StyledDateTimePicker = styled(BaseDateTimePicker)`
  .rw-widget-container.rw-widget-picker {
    border: ${(props) =>
      props.error ? '1px solid #dc3545' : '1px solid #ced4da'};
    box-shadow: none;
  }

  .rw-widget-picker > .rw-select {
    vertical-align: middle; /* IE11 fix */
  }
`

const DateTimePicker = ({
  input: { value, onChange, onBlur, onFocus },
  showTime,
  readOnly,
  meta: { touched, error, warning },
}) => (
  <React.Fragment>
    <StyledDateTimePicker // https://jquense.github.io/react-widgets/api/DateTimePicker/
      onChange={onChange}
      onBlur={() => {
        return onBlur() // https://github.com/jquense/react-widgets/issues/254#issuecomment-183875872
      }}
      onFocus={onFocus}
      culture="nl"
      format="d-M-yyyy H:mm"
      time={showTime}
      timeFormat="H:mm"
      value={isNaN(parseDate(value).getYear()) ? null : parseDate(value)}
      views={['month', 'year']}
      readOnly={readOnly}
      error={touched && error}
    />
    {touched &&
      ((error && <ErrorBlock>{error}</ErrorBlock>) ||
        (warning && <WarningBlock>{warning}</WarningBlock>))}
  </React.Fragment>
)

export default DateTimePicker
