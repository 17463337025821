import React from 'react'
import CKEditor from 'react-ckeditor-component'
import styled from 'styled-components'

const StyledCKEditor = styled(CKEditor)`
  & + span {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0.5rem;
  }
`

const StyledErrorWrapper = styled.div`
  padding-right: 2px; /* adjust the position of the line around the editor */
  border: ${(props) => (props.invalid ? '1px solid #dc3545' : null)};
`

export default class RichTextArea extends React.Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  onChange(evt) {
    this.props.onChange(evt.editor.getData())
  }

  onBlur(evt) {
    this.props.onBlur(evt.editor.getData())
  }

  CKEDITOR_CONFIG = {
    bodyClass: 'CKContentPage',
    toolbar: [
      {
        items: [
          'Bold',
          'Italic',
          'RemoveFormat',
          '-',
          'NumberedList',
          'BulletedList',
        ],
      },
      { items: ['Link', 'Unlink'] },
    ],
    width: '100%',
    height: 300,
    resize_minWidth: 700,
    resize_minHeight: 300,
    removePlugins: 'autogrow, image',
    extraPlugins: 'wordcount, notification',
    wordcount: {
      showParagraphs: false,
      showWordCount: false,
      showCharCount: true,
      countSpacesAsChars: true,
      countHTML: false,
      maxWordCount: -1, //negative one means unlimited
      maxCharCount: this.props.maxLength,
    },
    contentsCss: `${process.env.PUBLIC_URL}/ckeditor_contentsCss.css`,
  }

  render() {
    const { invalid, value, ...props } = this.props

    return (
      <StyledErrorWrapper invalid={invalid}>
        <StyledCKEditor
          {...props}
          scriptUrl={`${process.env.PUBLIC_URL}/ckeditor/ckeditor.js`}
          content={value}
          config={this.CKEDITOR_CONFIG}
          events={{
            blur: this.onBlur,
            change: this.onChange,
          }}
        />
      </StyledErrorWrapper>
    )
  }
}
