import React from 'react'
import { CustomInput } from 'reactstrap'
import { Field } from 'redux-form'

import { ErrorWrapper } from './ErrorWrapper'
import FormField from './FormField'
import { renderInput } from './renderers'

export default class ContractTypeSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      // show if contract_type is temporary
      showContractDurationField: props.input.value.toString() === '2',
    }
  }

  changeContractDurationField(evt) {
    // show or hide contract_duration field
    this.setState({
      showContractDurationField: !this.state.showContractDurationField,
    })

    if (evt.target.value === '1') {
      // clear field 'contract_duration' if contract_type is permanent (value: 1)
      this.props.changeFieldValue('translations[0].contract_duration', '')
    }
  }

  render() {
    const {
      choiceList,
      input,
      meta: { touched, error, warning },
    } = this.props

    return (
      <ErrorWrapper
        hasError={touched && (error || warning)}
        error={error}
        warning={warning}
      >
        {choiceList.map((choice) => (
          <Field
            key={choice.id}
            name={input.name}
            onChange={(evt) => this.changeContractDurationField(evt)}
            component={({ input }) => {
              let checked = false
              const choiceId = choice.id.toString() // choice.id is *sometimes* an int
              if (typeof input.value !== 'undefined') {
                checked = input.value.toString() === choiceId
              }
              return (
                <React.Fragment>
                  <CustomInput
                    key={choice.id}
                    type="radio"
                    id={choice.label}
                    {...input}
                    label={choice.label}
                    value={choice.id}
                    checked={checked}
                  />
                </React.Fragment>
              )
            }}
          />
        ))}

        {this.state.showContractDurationField && (
          <FormField
            label="Contractduur"
            type="input"
            name="translations[0].contract_duration"
            component={renderInput}
          />
        )}
      </ErrorWrapper>
    )
  }
}
