import { get as getSafe } from 'lodash'
import React from 'react'

import FormField from '../FormField'
import { renderRichTextArea } from '../renderers'
import withFormCard from './withFormCard'

function MiscInformation({ initialValues }) {
  return (
    <>
      <FormField
        label="Additionele informatie"
        name="translations[0].additional_info"
        type="textarea"
        component={renderRichTextArea}
        optional
        isOpen={!!getSafe(initialValues, 'translations[0].additional_info')}
        helpText="Geef hier in ieder geval het e-mailadres en/of telefoonnummer op van de vacaturehouder, zodat de sollicitant contact kan opnemen voor nadere informatie."
      />
    </>
  )
}

export default withFormCard(MiscInformation, {
  label: 'Overige informatie',
  htmlId: 'other_information_header',
  fields: ['additional_info'],
})
