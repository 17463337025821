import { get as getSafe } from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormGroup } from 'reactstrap'
import { Field } from 'redux-form'

import FormField from '../FormField'
import LocationSearchInput from '../LocationSearchInput'
import OptionalField from '../OptionalField'
import { renderInput, renderRichTextArea } from '../renderers'
import withFormCard from './withFormCard'

class Organisation extends Component {
  componentDidMount() {
    const { dispatch, countryChoices } = this.props
    if (!countryChoices.length) {
      dispatch.constants.loadCountries()
    }
  }

  render() {
    const { initialValues, changeFieldValue, countryChoices } = this.props
    return (
      <>
        <FormGroup>
          <OptionalField
            label="Informatie over de organisatie toevoegen"
            isOpen={
              !!(
                getSafe(initialValues, 'translations[0].organisation_name') ||
                getSafe(
                  initialValues,
                  'translations[0].organisation_description',
                )
              )
            }
          >
            <FormField
              component={renderInput}
              label="Organisatienaam"
              name="translations[0].organisation_name"
              type="text"
            />
            <FormField
              component={renderRichTextArea}
              label="Omschrijving organisatie"
              name="translations[0].organisation_description"
              type="text"
            />
          </OptionalField>
        </FormGroup>
        <FormGroup>
          <OptionalField
            label="Informatie over de afdeling toevoegen"
            isOpen={
              !!(
                getSafe(initialValues, 'translations[0].department_name') ||
                getSafe(initialValues, 'translations[0].department_description')
              )
            }
          >
            <FormField
              component={renderInput}
              label="Afdeling"
              name="translations[0].department_name"
              type="text"
            />
            <FormField
              component={renderRichTextArea}
              label="Omschrijving afdeling"
              name="translations[0].department_description"
              type="text"
            />
          </OptionalField>
        </FormGroup>
        <Field
          name="place_address"
          component={LocationSearchInput}
          changeFieldValue={changeFieldValue}
          country_choices={countryChoices}
          helpText="Geef het volledige adres van de standplaats op. Dit adres toont in een kaartje bij de vacature. Incomplete gegevens kunnen leiden tot verkeerde weergave in Google Maps."
        />
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    countryChoices: state.constants.countries || [],
  }
}

export default connect(mapStateToProps)(
  withFormCard(Organisation, {
    label: 'Organisatie',
    htmlId: 'organisation_header',
    fields: [
      'department_name',
      'organisation_description',
      'department_name',
      'department_description',
      'address',
      'postalcode',
      'city',
      'country_code',
    ],
  }),
)
