import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

export default function ConfirmationDialog({
  title = 'Weet je het zeker?',
  cancelText = 'Nee, annuleren',
  body,
  okButton = null,
  ...modalProps
}) {
  return (
    <Modal {...modalProps}>
      <ModalHeader tag="h4" className="bg-white">
        {title}
      </ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        <Button color="link" onClick={modalProps.toggle}>
          {cancelText}
        </Button>
        {okButton || <Button onClick={modalProps.toggle}>OK</Button>}
      </ModalFooter>
    </Modal>
  )
}
