import React from 'react'
import { Button } from 'reactstrap'
import styled from 'styled-components'

import Icon from '../Icon'

const StyledIcon = styled.div`
  display: inline-block;
  margin-right: 0.5rem;
  height: ${(props) => props.height || '16px'};
  width: ${(props) => props.width || '16px'};
  pointer-events: none;
`

export default class IconButton extends React.Component {
  render() {
    const { icon, label, height, width, ...buttonprops } = this.props
    return (
      <Button {...buttonprops}>
        <StyledIcon height={height} width={width}>
          <Icon icon={icon} />
        </StyledIcon>
        {label}
      </Button>
    )
  }
}
