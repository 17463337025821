import React from 'react'
import styled from 'styled-components'

import { ErrorWrapper } from './ErrorWrapper'
import { StyledSelectList } from './renderers'

const StyledSpan = styled.span`
  margin-left: 15px;
  text-decoration: underline;
`

const FunctionTypeList = styled(StyledSelectList)`
  padding: 10px 0 20px;
`

const splitChoices = function (functionTypeChoices) {
  /**
   * Split the list of function types into two lists
   * of scientific and non scientific function types
   */
  return {
    scientificChoices: functionTypeChoices.filter(
      (value) => value.is_scientific,
    ),
    nonScientificChoices: functionTypeChoices.filter(
      (value) => !value.is_scientific,
    ),
  }
}

export default class FunctionTypeSelect extends React.Component {
  constructor(props) {
    super(props)
    this.updateInput = this.updateInput.bind(this)
  }

  updateInput(values) {
    /**
     * Update the hidden input with the new values
     */
    this.props.input.onChange(
      values.filter(Boolean).map((value) => (value.id ? value.id : value)),
    )
  }

  render() {
    const {
      input,
      valueField,
      textField,
      itemComponent,
      meta: { touched, error, warning },
    } = this.props

    const { scientificChoices, nonScientificChoices } = splitChoices(
      this.props.data,
    )

    return (
      <ErrorWrapper
        hasError={touched && (error || warning)}
        error={error}
        warning={warning}
      >
        <StyledSpan>Wetenschappelijk</StyledSpan>
        <FunctionTypeList
          onChange={this.updateInput}
          data={scientificChoices}
          multiple={true}
          valueField={valueField}
          textField={textField}
          itemComponent={itemComponent}
          value={this.props.input.value}
        />
        <StyledSpan>Niet-wetenschappelijk</StyledSpan>
        <FunctionTypeList
          onChange={this.updateInput}
          data={nonScientificChoices}
          multiple={true}
          valueField={valueField}
          textField={textField}
          itemComponent={itemComponent}
          value={this.props.input.value}
        />
        <input {...input} type="hidden" />
      </ErrorWrapper>
    )
  }
}

FunctionTypeSelect.defaultProps = {
  data: [],
}
