import querystring from 'querystring'

import { get as getSafe } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import {
  SubmissionError,
  formValueSelector,
  isDirty,
  isSubmitting,
  initialize,
  submit,
} from 'redux-form'
import { Button } from 'reactstrap'

import IconButton from '../components/form/IconButton'
import StickyNotification from '../components/form/StickyNotification'
import SelfDismissingAlert from '../components/SelfDismissingAlert'
import BaseVacancyForm from '../components/form/VacancyForm'
import {
  deleteDraftVacancy,
  getDraftVacancy,
  getPublishedVacancy,
  publishDraftVacancy,
  saveDraftVacancy,
} from '../data/api'
import { addDays, parseDate } from '../utils/dateUtils'
import ConfirmationDialog from '../components/form/ConfirmationDialog'

function getDefaultValues(user, overrides = {}) {
  /**
   * Defaults for a new/duplicate draft vacancy
   */
  const now = new Date()
  const organisation = user.is_at_user
    ? overrides.organisation || ''
    : user.organisation_id
  return {
    available_positions: 1,
    country_code: 'NL',
    min_salary: 0,
    max_salary: 0,
    min_weekly_hours: 0,
    max_weekly_hours: 0,
    contract_type: 2, // ContractType.TEMPORARY
    // Overrides
    ...overrides,
    // Always set start and end date
    start_date: now,
    end_date: new Date(addDays(now, 59).setHours(23, 59)),
    organisation: organisation,

    // reset all import fields
    is_imported: false,
    include_in_import: false,
    import_id: null,

    // reset all template fields
    is_template: false,
    template_title: '',
  }
}

function SubmitRow({
  dirty,
  id,
  onCancel,
  onDelete,
  onDuplicate,
  onPublish,
  onSave,
  previewToken,
  submitting,
}) {
  return (
    <div className="d-flex">
      <div className="mr-auto">
        {id && (
          <>
            {previewToken && (
              <IconButton
                color="link"
                label="Preview"
                icon="eye"
                target="_blank"
                href={`/api/v1/vacancies/preview/${id}/?token=${previewToken}`}
                style={{ marginRight: '.5em' }}
              />
            )}
            <Button
              className="duplicate"
              onClick={onDuplicate}
              outline
              color="secondary"
              style={{ marginRight: '.5em' }}
            >
              Dupliceren
            </Button>
            {onDelete && (
              <Button
                color="danger"
                onClick={onDelete}
                style={{ marginRight: '.5em' }}
              >
                Verwijderen
              </Button>
            )}
          </>
        )}
      </div>
      <div>
        {onCancel && (
          <Button color="link" onClick={onCancel}>
            Annuleren
          </Button>
        )}
        {onPublish && (
          <Button
            type="submit"
            color="secondary"
            disabled={submitting || dirty}
            onClick={onPublish}
            style={{ marginRight: '.5em' }}
          >
            Publiceren
          </Button>
        )}
        {onSave && (
          <Button
            type="submit"
            color="primary"
            disabled={submitting}
            onClick={onSave}
          >
            Opslaan
          </Button>
        )}
      </div>
    </div>
  )
}

class DraftVacancyForm extends React.Component {
  /**
   * Form to create/duplicate and edit draft vacancies
   */
  constructor(props) {
    super(props)

    this.state = {
      saving: false,
      publishing: false,
      initialValues: {},
      previewToken: null,
      showConfirmationDialog: false,
    }

    // Bind event handlers
    this.deleteDraft = this.deleteDraft.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.saveDraft = this.saveDraft.bind(this)
    this.publishDraft = this.publishDraft.bind(this)
    this.duplicateDraft = this.duplicateDraft.bind(this)
    this.toggleConfirmationDialog = this.toggleConfirmationDialog.bind(this)
  }

  async componentDidMount() {
    // Get form data
    const { id, duplicate, user } = this.props

    // Create draft from existing draft or vacancy
    if (duplicate) {
      let initialValues
      if (duplicate.draft) {
        const { data } = await getDraftVacancy(duplicate.draft)
        initialValues = data.data
      } else {
        const { data } = await getPublishedVacancy(duplicate.vacancy)
        initialValues = data.data
      }
      this.setState({
        initialValues: getDefaultValues(user, initialValues),
        previewToken: null,
      })
    }

    // Editing a draft, load draft data
    else if (id) {
      const { data } = await getDraftVacancy(id)
      const startDate = parseDate(data.data.start_date)
      const now = new Date()
      this.setState({
        initialValues: {
          ...data.data,
          // Always set start date
          start_date: startDate && startDate >= now ? startDate : now,
          // template fields are outside data.data object
          is_template: data.is_template,
          template_title: data.template_title,
        },
        previewToken: data.preview_token || null,
      })
    }

    // Creating a new draft, provide some sane defaults
    else {
      this.setState({
        initialValues: getDefaultValues(user),
        previewToken: null,
      })
    }
  }

  saveDraft() {
    // Event handler for save button
    const { dispatch } = this.props
    this.setState(
      {
        saving: true,
        publishing: false,
      },
      () => dispatch(submit('vacancyForm')),
    )
  }

  publishDraft() {
    // Event handler for publish button
    const { dispatch } = this.props
    this.setState(
      {
        saving: false,
        publishing: true,
      },
      () => dispatch(submit('vacancyForm')),
    )
  }

  duplicateDraft() {
    // Event handler for duplicate button
    const { history, id } = this.props
    history.push(`/drafts/duplicate?draft=${id}`)
  }

  onCancel() {
    // Event handler for cancel button
    const { history } = this.props
    history.push(`/vacancies/list#draft`)
  }

  async onSubmit(values) {
    // onSubmit handler for redux-form
    const { dispatch, history, id } = this.props

    try {
      // Saving a draft
      if (this.state.saving) {
        const { data } = await saveDraftVacancy(id, values)
        this.setState(
          {
            saving: false,
            publishing: false,
            previewToken: data.preview_token || null,
          },
          () => {
            // Done, re-initialize form with the new data
            dispatch(
              initialize(
                'vacancyForm',
                {
                  ...data.data,

                  // template fields are outside data.data object
                  is_template: data.is_template,
                  template_title: data.template_title,
                },
                {
                  keepDirty: false,
                },
              ),
            )
            history.push(`/drafts/edit/${data.id}`, {
              message: 'Succes: de wijzigingen zijn opgeslagen',
            })
          },
        )
      }

      // Publishing a draft
      else {
        const { data } = await publishDraftVacancy(id, values)
        this.setState(
          {
            saving: false,
            publishing: false,
          },
          () =>
            // Done, redirect to published overview
            history.push('/vacancies/list#published', {
              message: 'Succes: de vacature is gepubliceerd. ',
              short_url: data.data.short_url,
            }),
        )
      }
    } catch (error) {
      // Saving/publishing failed
      const { response } = error
      if (response && response.status === 400) {
        // Let redux-form know about validation errors in the API response
        throw new SubmissionError({
          ...response.data.data,
          _error: getSafe(
            response,
            'data.non_field_errors',
            'De actie is mislukt: los de gemelde issues in het formulier op en probeer opnieuw',
          ),
        })
      } else {
        // Unknown error (e.g. 500)
        throw new SubmissionError({ _error: 'Er ging iets mis' })
      }
    }
  }

  toggleConfirmationDialog() {
    // Event handler for delete/archive button (and cancel in the dialog)
    this.setState({
      showConfirmationDialog: !this.state.showConfirmationDialog,
    })
  }

  async deleteDraft() {
    // Event handler for OK button in delete dialog
    const { history, id } = this.props

    await deleteDraftVacancy(id)
    history.push(`/vacancies/list#draft`, {
      message: 'Succes: het concept is verwijderd',
    })
  }

  render() {
    const { id, user, dirty, duplicate, submitting, isTemplate } = this.props
    const { initialValues, previewToken } = this.state

    if (Object.keys(initialValues).length === 0) {
      // Prevent binding the initialValues to the form too early
      return null
    }

    let notification = null
    if (duplicate) {
      notification = (
        <SelfDismissingAlert>
          <StickyNotification color="success">
            {duplicate.draft
              ? 'Succes: De concept vacature is gedupliceerd'
              : 'Succes: de vacature is gedupliceerd'}
          </StickyNotification>
        </SelfDismissingAlert>
      )
    }

    const submitRow = (
      <SubmitRow
        {...{
          dirty,
          id,
          previewToken,
          submitting,
          onCancel: this.onCancel,
          onDelete:
            (!isTemplate || user.allow_edit_templates) &&
            this.toggleConfirmationDialog,
          onDuplicate: this.duplicateDraft,
          onPublish:
            id &&
            !isTemplate &&
            user.allow_publish_vacancies &&
            this.publishDraft,
          onSave: (!isTemplate || user.allow_edit_templates) && this.saveDraft,
        }}
      />
    )

    return (
      <>
        {notification}
        <BaseVacancyForm
          user={user}
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          isDraft={true}
          isTemplate={isTemplate}
          id={id}
          title={
            duplicate
              ? 'Vacature dupliceren'
              : isTemplate
                ? 'Vacature template'
                : 'Concept vacature'
          }
          submitRow={submitRow}
        />
        <ConfirmationDialog
          isOpen={this.state.showConfirmationDialog}
          toggle={this.toggleConfirmationDialog}
          body="Een verwijderde concept vacature kan niet meer worden teruggehaald. Weet je zeker dat je deze concept vacature wil verwijderen?"
          okButton={
            <Button
              color="danger"
              onClick={this.deleteDraft}
              style={{ marginRight: '.5em' }}
            >
              Verwijderen
            </Button>
          }
        />
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const user = state.auth.me
  const id = ownProps.match.params.id

  let duplicate = null
  const { pathname, search } = ownProps.location
  const query = querystring.parse(search.substr(1))
  if (pathname.endsWith('/duplicate') && (query.draft || query.vacancy)) {
    duplicate = { draft: query.draft, vacancy: query.vacancy }
  }

  // redux-form selectors
  const vacancyFromvalueSelector = formValueSelector('vacancyForm')
  const submitting = isSubmitting()(state)
  const dirty = isDirty('vacancyForm')(state)

  return {
    dirty,
    id,
    duplicate,
    submitting,
    user,
    isTemplate: vacancyFromvalueSelector(state, 'is_template'),
  }
}

export default connect(mapStateToProps)(DraftVacancyForm)
