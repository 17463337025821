import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { connect } from 'react-redux'
import { Redirect, Route, withRouter } from 'react-router-dom'

import { select } from '../index'

const _PrivateRoute = ({
  component: Component,
  isLoggedIn,
  isLoading,
  isLoaded,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn ? (
        <Component {...props} />
      ) : isLoading || !isLoaded ? (
        <Skeleton count={4} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
)

export default withRouter(
  connect((state) => {
    return {
      isLoggedIn: select.auth.isLoggedIn(state),
      isLoading: state.loading.models.auth,
      isLoaded: state.auth.loaded,
    }
  })(_PrivateRoute),
)
