import { get as getSafe } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { FormGroup } from 'reactstrap'

import FormField from '../FormField'
import OptionalField from '../OptionalField'
import ContractTypeSelect from '../ContractTypeSelect'
import { renderInput, renderRichTextArea } from '../renderers'
import withFormCard from './withFormCard'

function ContractTerms({
  changeFieldValue,
  contractTypeChoices,
  initialValues,
}) {
  const contract_type = getSafe(initialValues, 'contract_type', 2),
    contract_duration = getSafe(
      initialValues,
      'translations[0].contract_duration',
      '',
    ),
    min_weekly_hours = getSafe(initialValues, 'min_weekly_hours', 0),
    max_weekly_hours = getSafe(initialValues, 'max_weekly_hours', 0),
    min_salary = getSafe(initialValues, 'min_salary', 0),
    max_salary = getSafe(initialValues, 'max_salary', 0)

  return (
    <>
      <FormField
        component={renderRichTextArea}
        label="Arbeidsvoorwaarden"
        name="translations[0].contract_terms"
        type="textarea"
        placeholder="De volledige arbeidsvoorwaarden, zowel primair als secundair."
      />

      <FormField
        inline
        label="Dienstverband"
        name="contract_type"
        component={ContractTypeSelect}
        choiceList={contractTypeChoices}
        changeFieldValue={changeFieldValue}
        optional={true}
        isOpen={contract_type.toString() !== '2' || contract_duration !== ''}
        helpText="Alleen bij de keuze voor een dienstverband voor bepaalde tijd en ingevulde contractuur, worden deze gegevens in de vacature vermeld als eerste zin in het onderdeel arbeidsvoorwaarden."
      />

      <FormGroup>
        <OptionalField
          label="Informatie over aantal uren per week toevoegen"
          isOpen={
            parseFloat(min_weekly_hours) !== 0 ||
            parseFloat(max_weekly_hours) !== 0
          }
        >
          <FormField
            inline
            component={renderInput}
            label="Minimaal aantal uren per week"
            name="min_weekly_hours"
            type="number"
            min={0}
            step={0.1}
            append={{ singular: 'uur', plural: 'uren' }}
          />
          <FormField
            inline
            component={renderInput}
            label="Maximaal aantal uren per week"
            name="max_weekly_hours"
            type="number"
            min={0}
            step={0.1}
            append={{ singular: 'uur', plural: 'uren' }}
          />
        </OptionalField>
      </FormGroup>

      <FormGroup>
        <OptionalField
          label="Informatie over maandsalaris toevoegen"
          isOpen={
            min_salary.toString() !== '0' || max_salary.toString() !== '0'
          }
        >
          <FormField
            inline
            component={renderInput}
            label="Minimaal maandsalaris"
            name="min_salary"
            type="number"
            min={0}
            prepend="€"
            helpText="Vul een minimum salaris of een indicatie in. Kandidaten willen graag weten waar ze aan toe zijn."
          />
          <FormField
            inline
            component={renderInput}
            label="Maximaal maandsalaris"
            name="max_salary"
            type="number"
            min={0}
            prepend="€"
          />
        </OptionalField>
      </FormGroup>
    </>
  )
}

function mapStateToProps(state) {
  return {
    contractTypeChoices: state.constants.vacancies.contract_type_choices || [],
  }
}

export default connect(mapStateToProps)(
  withFormCard(ContractTerms, {
    label: 'Arbeidsvoorwaarden',
    htmlId: 'working_conditions_header',
    fields: [
      'contract_terms',
      'contract_type',
      'contract_duration',
      'min_weekly_hours',
      'max_weekly_hours',
      'min_salary',
      'max_salary',
    ],
  }),
)
