import { transparentize } from 'polished'
import React from 'react'
import { Button } from 'reactstrap'
import styled, { css } from 'styled-components'

import Icon from '../Icon'

const StyledButton = styled(Button)`
  border-color: ${(props) => transparentize(0.9, props.theme.secondary)};
  background-color: ${(props) => transparentize(0.9, props.theme.secondary)};
  color: ${(props) => props.theme.secondary};

  &:hover {
    border-color: ${(props) => transparentize(0.9, props.theme.secondary)};
    background-color: ${(props) => transparentize(0.9, props.theme.secondary)};
    color: ${(props) => props.theme.secondary};
    ${(props) =>
      props.link &&
      css`
        background-color: transparent;
        border-color: transparent;
        text-align: left;
        padding-left: 0;
      `};
  }

  ${(props) =>
    props.link &&
    css`
      background-color: transparent;
      border-color: transparent;
      text-align: left;
      padding-left: 0;
    `};
`

export default class OptionalField extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      open: this.props.isOpen || false,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.setState({
        open: this.props.isOpen,
      })
    }
  }

  toggle() {
    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    const { children, label } = this.props
    const { open } = this.state
    return open ? (
      children
    ) : (
      <StyledButton color="secondary" block onClick={this.toggle}>
        <Icon
          icon="plus"
          height={10}
          width={10}
          style={{ marginRight: '0.5rem' }}
        />
        {label || 'Toevoegen'}
      </StyledButton>
    )
  }
}
