import React from 'react'
import { Alert } from 'reactstrap'
import styled from 'styled-components'

const StyledStickyNotification = styled(Alert)`
  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    margin: 0;
    border-radius: 0;
  }
`

export default class StickyNotification extends React.Component {
  constructor(props) {
    super(props)

    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    /* calling handleScroll directly is somehow too quick :/ */
    setTimeout(this.handleScroll, 500)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    const sticky_notification =
      document.querySelector('.navbar + div').getBoundingClientRect().bottom <=
      0
    const alert = document.querySelector('.alert')
    if (alert) {
      if (sticky_notification) {
        alert.classList.add('sticky')
      } else {
        alert.classList.remove('sticky')
      }
    }
  }

  render() {
    const { children, ...attributes } = this.props
    return (
      <StyledStickyNotification {...attributes}>
        {children}
      </StyledStickyNotification>
    )
  }
}
