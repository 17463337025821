import { get as getSafe } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { FieldArray, formValueSelector } from 'redux-form'
import { FormGroup } from 'reactstrap'
import withFormCard from './withFormCard'
import FormField from '../FormField'
import { renderInput } from '../renderers'
import SwitchCheckBox from '../SwitchCheckBox'
import { Button } from 'reactstrap'
import { transparentize } from 'polished'
import { Col, Row } from 'reactstrap'

import styled, { css } from 'styled-components'
import Icon from '../../Icon'
import ToolTip from '../Tooltip'

const AddAnotherButton = styled(Button)`
  border-color: ${(props) => transparentize(0.9, props.theme.secondary)};
  background-color: ${(props) => transparentize(0.9, props.theme.secondary)};
  color: ${(props) => props.theme.secondary};

  &:hover {
    border-color: ${(props) => transparentize(0.9, props.theme.secondary)};
    background-color: ${(props) => transparentize(0.9, props.theme.secondary)};
    color: ${(props) => props.theme.secondary};
    ${(props) =>
      props.link &&
      css`
        background-color: transparent;
        border-color: transparent;
        text-align: left;
        padding-left: 0;
      `};
  }

  ${(props) =>
    props.link &&
    css`
      background-color: transparent;
      border-color: transparent;
      text-align: left;
      padding-left: 0;
    `};
`

const CloseButton = styled(Button)`
  border: 0 none;
  background-color: transparent;

  border-radius: 50%;
`

function renderDashboardAccess({ fields, accessValue }) {
  return (
    <>
      {fields.map((dashboard_access, index) => (
        <FormGroup key={index} row>
          <Col>
            <FormField
              name={`${dashboard_access}.email`}
              type="email"
              component={renderInput}
            />
          </Col>

          <Col sm={4}>
            <FormField
              component={SwitchCheckBox}
              name={`${dashboard_access}.can_view_metrics`}
              isChecked={!!getSafe(accessValue, [index, 'can_view_metrics'])}
            />
          </Col>

          <Col sm={3}>
            <FormField
              component={SwitchCheckBox}
              name={`${dashboard_access}.can_perform_vacancy_match`}
              isChecked={
                !!getSafe(accessValue, [index, 'can_perform_vacancy_match'])
              }
            />
          </Col>

          <Col sm={1}>
            <div className="form-group">
              <CloseButton onClick={() => fields.remove(index)} color="light">
                <Icon height={24} width={10} icon="times" />
              </CloseButton>
            </div>
          </Col>
        </FormGroup>
      ))}
      <AddAnotherButton
        onClick={() =>
          fields.push({
            can_view_metrics: true,
            can_perform_vacancy_match: true,
          })
        }
        block
      >
        <Icon
          icon="plus"
          height={10}
          width={10}
          style={{ marginRight: '0.5rem' }}
        />
        Dashboard toegang toevoegen
      </AddAnotherButton>
    </>
  )
}

function DashboardAccess({ initialValues, submitErrors, accessValue }) {
  return (
    <>
      <Row className="form-group">
        <Col>
          E-mailadres{' '}
          <ToolTip>
            Voer het e-mailadres van de gebruiker waarvoor de toegang wordt
            verleend in.
          </ToolTip>
        </Col>
        <Col sm={4}>Kan statistieken zien</Col>
        <Col sm={4}>Kan vacaturematch doen</Col>
      </Row>
      <FieldArray
        name="dashboard_access"
        component={renderDashboardAccess}
        initialValues={initialValues}
        submitErrors={submitErrors}
        accessValue={accessValue}
      />
    </>
  )
}

const selector = formValueSelector('vacancyForm')
export default connect((state) => ({
  accessValue: selector(state, 'dashboard_access'),
}))(
  withFormCard(DashboardAccess, {
    label: 'Dashboard toegang',
    htmlId: 'dashboard_access',
    fields: ['dashboard_access'],
  }),
)
