import React from 'react'
import { UncontrolledTooltip as BaseTooltip } from 'reactstrap'
import styled from 'styled-components'

import Icon from '../Icon'

const StyledIcon = styled(Icon)`
  margin-left: 0.5rem;
`

const StyledTooltip = styled(BaseTooltip)`
  .tooltip-inner {
    max-width: 400px;
    text-align: left;
    padding: 15px 20px;
  }
`

export default class Tooltip extends React.Component {
  constructor(props) {
    super(props)

    this.id = `__tooltip__${Math.random().toString(36).substr(2)}`
  }

  render() {
    return (
      <>
        <StyledIcon icon="help_circled" id={this.id} height="16" width="16" />
        <StyledTooltip placement="top" target={this.id}>
          {this.props.children}
        </StyledTooltip>
      </>
    )
  }
}
