import { get as getSafe } from 'lodash'
import React from 'react'

import {
  getOrganisationsList,
  getOrganisation,
  getMediaPartnersList,
  getMediaPartner,
  getProjectsList,
  getProject,
} from '../../../data/api'
import {
  required,
  validatePublicationDates,
} from '../../../utils/validationUtils'
import FormField from '../FormField'
import { renderInput, renderFilterDropdown, renderSelect } from '../renderers'
import CheckboxShowField from '../CheckboxShowField'
import DateTimePicker from '../DateTimePicker'
import SwitchCheckBox from '../SwitchCheckBox'
import withFormCard from './withFormCard'

const LANGUAGE_OPTIONS = [
  { label: 'Nederlands', value: 'nl' },
  { label: 'Engels', value: 'en' },
]

function GeneralInformation({
  initialValues,
  id,
  user,
  isDraft = false,
  isTemplate = false,
}) {
  {
    const isPublished = !isDraft
    const isScheduled =
      isPublished && new Date(initialValues.start_date) > new Date()
    const isArchived =
      isPublished && new Date(initialValues.end_date) < new Date()
    const isImported = isPublished && !!initialValues.is_imported

    return (
      <>
        {isDraft && user.allow_edit_templates && (
          <CheckboxShowField
            name="is_template"
            label="Template"
            isOpen={!!getSafe(initialValues, 'is_template')}
          >
            <FormField
              inline
              component={renderInput}
              label="Template titel"
              name="template_title"
              validate={[required]}
            />
          </CheckboxShowField>
        )}

        <FormField
          inline
          optional
          component={renderInput}
          label="Vacaturenummer"
          name="code"
          type="text"
          isOpen={!!getSafe(initialValues, 'code')}
          helpText="Vul een uniek nummer in waaraan iedereen (jijzelf, wij én sollicitant) de vacature kan herkennen."
        />

        {user.is_at_user && (
          <>
            <FormField
              inline
              component={renderFilterDropdown}
              label="Organisatie"
              name="organisation"
              type="number"
              getList={getOrganisationsList}
              getItem={getOrganisation}
            />
            <FormField
              inline
              optional
              label="Media Partner"
              name="media_partner"
              isOpen={!!getSafe(initialValues, 'media_partner')}
              component={renderFilterDropdown}
              getList={getMediaPartnersList}
              getItem={getMediaPartner}
            />
            <FormField
              inline
              optional
              label="Project"
              name="project"
              isOpen={!!getSafe(initialValues, 'project')}
              component={renderFilterDropdown}
              getList={getProjectsList}
              getItem={getProject}
            />
          </>
        )}
        <FormField
          inline
          component={renderSelect}
          label="Taal"
          name="translations[0].language_code"
          type="select"
          options={LANGUAGE_OPTIONS}
          labelKey="label"
          valueKey="value"
          helpText="Kies of je de vacature in het Engels of in het Nederlands wil invoeren. Engelse vacatures plaatsen wij automatisch door naar relevante kanalen, zoals Euraxess. Wil je de vacature in beide talen invoeren? Maak dan twee vacatures aan. Hiermee vergroot je het bereik en het aantal sollicitanten aanzienlijk."
        />
        <FormField
          inline
          optional
          component={renderInput}
          label="Beschikbare posities"
          name="available_positions"
          type="number"
          min="1"
          placeholder="1"
          isOpen={!!(getSafe(initialValues, 'available_positions') !== 1)}
          helpText="Standaardwaarde is 1. Klik op toevoegen als je voor meer posities werft."
        />
        {!isTemplate && (
          <>
            <FormField
              inline
              component={DateTimePicker}
              label="Startdatum"
              name="start_date"
              showTime={true}
              readOnly={!isDraft && !isScheduled}
              validate={
                isDraft || isScheduled ? [validatePublicationDates] : []
              }
              helpText="Vul de datum en het tijdstip in waarop de vacature online komt op onze website."
            />
            <FormField
              inline
              component={DateTimePicker}
              label="Einddatum"
              name="end_date"
              showTime={true}
              readOnly={isArchived}
              validate={!isArchived ? [validatePublicationDates] : []}
              helpText="Kies een dag en tijdstip waarop de vacature offline gaat."
            />
          </>
        )}
        {user.is_at_user && (
          <FormField
            inline
            component={SwitchCheckBox}
            label="SciencePlatform"
            name="scienceplatform"
            isChecked={!!getSafe(initialValues, 'scienceplatform')}
          />
        )}
        {user.is_at_user && isImported && (
          <FormField
            inline
            component={SwitchCheckBox}
            label="Import mag data overschrijven?"
            name="include_in_import"
            isChecked={!!getSafe(initialValues, 'include_in_import')}
          />
        )}
        {user.allow_internal_vacancies && (
          <FormField
            inline
            component={SwitchCheckBox}
            label="Interne vacature"
            name="is_internal_vacancy"
            isChecked={!!getSafe(initialValues, 'is_internal_vacancy')}
          />
        )}
      </>
    )
  }
}

export default withFormCard(GeneralInformation, {
  label: 'Algemeen',
  htmlId: 'general_header',
  fields: [
    'is_template',
    'template_title',
    'code',
    'organisation',
    'media_partner',
    'project',
    'language_code',
    'available_positions',
    'start_date',
    'end_date',
    'scienceplatform',
    'include_in_import',
    'is_internal_vacancy',
  ],
})
