import React from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'

export default ({ children, id, title, ...props }) => (
  <Card outline className="mb-3" {...props}>
    <CardHeader tag="h4" className="bg-white" id={id}>
      {title}
    </CardHeader>
    <CardBody>{children}</CardBody>
  </Card>
)
