import './style/App.css'

import { get as getSafe } from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { Col, Container, Nav, Navbar, NavbarBrand, Row } from 'reactstrap'
import { ThemeProvider } from 'styled-components'

import AuthButton from './components/AuthButton'
import ErrorBoundary from './components/ErrorBoundary'
import Loadable from './components/Loadable'
import NavLink from './components/NavLink'
import PrivateRoute from './components/PrivateRoute'
import DraftVacancyForm from './views/DraftVacancyForm'
import Login from './views/Login'
import PublishedVacancyForm from './views/PublishedVacancyForm'
import VacancyListView from './views/VacancyListView'
import { select } from './index'

const theme = {
  secondary: '#40bcd7',
  info: '#0dcaf0',
  gray300: '#e9ecef',
}

class App extends Component {
  componentDidMount() {
    const { dispatch } = this.props
    dispatch.auth.checkSession()
  }

  render() {
    const { loading, isLoggedIn } = this.props

    return (
      <ThemeProvider theme={theme}>
        <Loadable active={loading}>
          <div style={{ minHeight: '100vh' }}>
            <Navbar
              dark
              className="navbar-expand-sm"
              style={{
                backgroundColor: '#004B67',
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
              }}
            >
              <Container>
                <NavbarBrand href="/">
                  <strong>AcademicTransfer</strong> Admin
                </NavbarBrand>
                {isLoggedIn && (
                  <Nav navbar>
                    <NavLink to="/vacancies/list">Vacatures</NavLink>
                  </Nav>
                )}
                <Nav navbar>
                  <AuthButton />
                </Nav>
              </Container>
            </Navbar>

            <Row
              className="text-light"
              noGutters
              style={{ backgroundColor: '#004059' }}
            >
              <Container>
                <Row className="sub-menu">
                  <Col xs="6">
                    <h3 className="organisation-name">
                      {this.props.organisation_name}
                    </h3>
                  </Col>
                </Row>
              </Container>
            </Row>

            <Container className="main-content">
              <ErrorBoundary>
                <Switch>
                  <Route path="/login" component={Login} />
                  <PrivateRoute
                    path="/vacancies/list"
                    component={VacancyListView}
                    key="VacancyListView"
                  />
                  {/*
                    The DraftVacancyForm routes have keys to make
                    sure they are re-mounted when navigating to them
                    */}
                  <PrivateRoute
                    path="/drafts/create"
                    component={DraftVacancyForm}
                    key="DraftVacancyCreateView"
                  />
                  <PrivateRoute
                    path="/drafts/duplicate"
                    component={DraftVacancyForm}
                    key="DraftVacancyDuplicateView"
                  />
                  <PrivateRoute
                    path="/drafts/edit/:id"
                    component={DraftVacancyForm}
                    key="DraftVacancyEditView"
                  />
                  <PrivateRoute
                    path="/vacancies/edit/:external_id"
                    component={PublishedVacancyForm}
                  />
                  <Redirect path="/" to="/vacancies/list" />
                </Switch>
              </ErrorBoundary>
            </Container>
          </div>
        </Loadable>
      </ThemeProvider>
    )
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: select.auth.isLoggedIn(state),
    organisation_name: getSafe(state, 'auth.me.organisation_name', ''),
    loading: state.loading.effects.auth.checkSession,
  }
}

export default withRouter(connect(mapStateToProps)(App))
