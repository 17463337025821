import React from 'react'
import { Input, Label } from 'reactstrap'
import styled from 'styled-components'

const StyledLabel = styled(Label)`
  &.form-check-label {
    background-color: #c4c3c3;
    height: 1.5rem;
    width: 2.5rem;
    border-radius: 2.5rem;
    position: relative;
    vertical-align: middle;

    &:after {
      content: '';
      border-radius: 50%;
      background-color: white;
      position: absolute;
      right: calc(2.5rem - 1.25rem - 0.125rem);
      top: 0.125rem;
      height: 1.25rem;
      width: 1.25rem;
      transition: all 0.1s;
    }
  }
`

const StyledInput = styled(Input)`
  &[type='checkbox'] {
    display: none;
  }

  &:checked + .form-check-label {
    background-color: ${(props) => props.theme.secondary};

    &:after {
      right: 0.125rem;
    }
  }
`
export default class SwitchCheckBox extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isChecked: this.props.isChecked,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isChecked !== prevProps.isChecked) {
      this.setState({
        isChecked: this.props.isChecked,
      })
    }
  }

  toggle() {
    this.setState({
      isChecked: !this.state.isChecked,
    })
  }

  render() {
    const { input } = this.props
    return (
      <React.Fragment>
        <StyledInput
          type="checkbox"
          id={input.name}
          onChange={this.toggle}
          onClick={this.toggle}
          checked={this.state.isChecked}
          {...input}
        />
        <StyledLabel check for={input.name} />
      </React.Fragment>
    )
  }
}
