import React from 'react'

export default class SelfDismissingAlert extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hide: false,
    }
    this.timer = null
  }

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({ hide: true })
    }, 10000)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  render() {
    return !this.state.hide && this.props.children
  }
}
