import { get as getSafe } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { SubmissionError, isSubmitting, submit } from 'redux-form'
import { Button } from 'reactstrap'

import ConfirmationDialog from '../components/form/ConfirmationDialog'
import StickyNotification from '../components/form/StickyNotification'
import BaseVacancyForm from '../components/form/VacancyForm'
import {
  archivePublishedVacancy,
  deletePublishedVacancy,
  getPublishedVacancy,
  savePublishedVacancy,
} from '../data/api'

function SubmitRow({
  onCancel,
  onDelete,
  onDuplicate,
  onPublish,
  isScheduled,
  submitting,
}) {
  return (
    <div className="d-flex">
      <div className="mr-auto">
        {onDuplicate && (
          <Button
            className="duplicate"
            onClick={onDuplicate}
            outline
            color="secondary"
            style={{ marginRight: '.5em' }}
          >
            Dupliceren
          </Button>
        )}
        {onDelete && (
          <Button
            color="danger"
            onClick={onDelete}
            style={{ marginRight: '.5em' }}
          >
            {isScheduled ? 'Verwijderen' : 'Archiveren'}
          </Button>
        )}
      </div>
      <div>
        {onCancel && (
          <Button color="link" onClick={onCancel}>
            Annuleren
          </Button>
        )}
        {onPublish && (
          <Button
            type="submit"
            color="primary"
            disabled={submitting}
            onClick={onPublish}
          >
            Opslaan en publiceren
          </Button>
        )}
      </div>
    </div>
  )
}

class PublishedVacancyForm extends React.Component {
  /**
   * Form to edit published vacancies
   */
  constructor(props) {
    super(props)

    this.state = {
      initialValues: {},
      isScheduled: null,
      showConfirmationDialog: false,
    }

    // Bind event handlers
    this.onCancel = this.onCancel.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.publishVacancy = this.publishVacancy.bind(this)
    this.duplicateVacancy = this.duplicateVacancy.bind(this)
    this.archiveOrDeleteVacancy = this.archiveOrDeleteVacancy.bind(this)
    this.toggleConfirmationDialog = this.toggleConfirmationDialog.bind(this)
  }

  async componentDidMount() {
    // Get form data
    const { id } = this.props
    const { data } = await getPublishedVacancy(id)
    const initialValues = data.data
    const now = new Date()

    this.setState({
      initialValues,
      isScheduled: new Date(initialValues.start_date) > now,
    })
  }

  publishVacancy() {
    // Event handler for publish button
    const { dispatch } = this.props
    dispatch(submit('vacancyForm'))
  }

  duplicateVacancy() {
    // Event handler for duplicate button
    const { history, id } = this.props
    history.push(`/drafts/duplicate?vacancy=${id}`)
  }

  onCancel() {
    // Event handler for cancel button
    const { history } = this.props
    history.push(`/vacancies/list#published`)
  }

  async onSubmit(values) {
    // onSubmit handler for redux-form
    const { id, history } = this.props
    try {
      const response = await savePublishedVacancy(id, values)
      history.push('/vacancies/list#published', {
        message: 'Succes: de vacature is gepubliceerd. ',
        short_url: response.data.data.short_url,
      })
    } catch (error) {
      // Publishing failed
      const { response } = error
      if (response && response.status === 400) {
        // Let redux-form know about validation errors in the API response
        throw new SubmissionError({
          ...response.data.data,
          _error: getSafe(
            response,
            'data.non_field_errors',
            'De actie is mislukt: los de gemelde issues in het formulier op en probeer opnieuw',
          ),
        })
      } else {
        // Unknown error (e.g. 500)
        throw new SubmissionError({ _error: 'Er ging iets mis' })
      }
    }
  }

  toggleConfirmationDialog() {
    // Event handler for delete/archive button (and cancel in the dialog)
    this.setState({
      showConfirmationDialog: !this.state.showConfirmationDialog,
    })
  }

  async archiveOrDeleteVacancy() {
    // Event handler for OK button in delete/archive dialog
    const { history, id } = this.props
    const { isScheduled } = this.state

    if (isScheduled) {
      await deletePublishedVacancy(id)
      this.props.history.push(`/vacancies/list#published`, {
        message: 'Succes: de vacature is verwijderd',
      })
    } else {
      await archivePublishedVacancy(id)
      history.push(`/vacancies/list#archived`, {
        message: 'Succes: de vacature is gearchiveerd',
      })
    }
  }

  render() {
    const { user, id, submitting } = this.props
    const { initialValues, isScheduled } = this.state

    if (Object.keys(initialValues).length === 0) {
      // Prevent binding the initialValues to the form too early
      return null
    }

    const imported = !!initialValues.include_in_import
    const editWarning = imported && (
      <StickyNotification color="warning">
        <strong>Let op:</strong> deze vacature wordt bijgewerkt vanuit een
        externe bron waardoor aanpassing(en) ongedaan gemaakt zullen worden.
        <br />
        Voer wijziging(en) in de bron door of neem contact op met{' '}
        <a href="mailto:service@academictransfer.nl">AcademicTransfer</a> om de
        vacature aan te passen.
      </StickyNotification>
    )

    const submitRow = (
      <SubmitRow
        {...{
          submitting,
          onCancel: this.onCancel,
          onDelete:
            user.allow_publish_vacancies && this.toggleConfirmationDialog,
          onDuplicate: this.duplicateVacancy,
          onPublish: user.allow_publish_vacancies && this.publishVacancy,
          isScheduled,
        }}
      />
    )

    return (
      <>
        {editWarning}
        <BaseVacancyForm
          user={user}
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          isDraft={false}
          isTemplate={false}
          id={id}
          title="Vacature bewerken"
          submitRow={submitRow}
        />
        <ConfirmationDialog
          isOpen={this.state.showConfirmationDialog}
          toggle={this.toggleConfirmationDialog}
          body={
            isScheduled
              ? 'Een verwijderde vacature kan niet meer worden teruggehaald. Weet je zeker dat je deze vacature wil verwijderen?'
              : 'Een gearchiveerde vacature kan niet meer gepubliceerd worden. Weet je zeker dat je deze vacature wil archiveren?'
          }
          okButton={
            <Button
              color="danger"
              onClick={this.archiveOrDeleteVacancy}
              style={{ marginRight: '.5em' }}
            >
              {isScheduled ? 'Verwijderen' : 'Archiveren'}
            </Button>
          }
        />
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const user = state.auth.me
  const id = ownProps.match.params.external_id

  // redux-form selectors
  const submitting = isSubmitting('vacancyForm')(state)

  return {
    id,
    submitting,
    user,
  }
}

export default connect(mapStateToProps)(PublishedVacancyForm)
