import 'react-app-polyfill/ie11'
import 'core-js/es6/array'
import 'core-js/es6/number'
import 'core-js/es6/string'
import './style/bootstrap.css'
import { init } from '@rematch/core'
import createLoadingPlugin from '@rematch/loading'
import selectPlugin, { getSelect } from '@rematch/select'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { reducer as reduxFormReducer } from 'redux-form'
import TagManager from 'react-gtm-module'

import App from './App'
import * as models from './data/models'

export const select = getSelect()
const loading = createLoadingPlugin()

export const store = init({
  models,
  plugins: [selectPlugin(), loading],
  redux: {
    reducers: {
      form: reduxFormReducer,
    },
  },
})

TagManager.initialize({
  gtmId: 'GTM-TDV2C6D',
})

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root'),
)
