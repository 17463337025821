import 'react-select/dist/react-select.css'
import 'react-widgets/dist/css/react-widgets.css'

import React from 'react'
import Select from 'react-select'
import { SelectList } from 'react-widgets'
import { Input, InputGroup, InputGroupAddon } from 'reactstrap'
import styled from 'styled-components'

import { ErrorBlock, ErrorWrapper, WarningBlock } from './ErrorWrapper'
import RichTextArea from './RichTextArea'
import FilterDropdown from '../FilterDropdown'

export const renderFilterDropdown = ({
  input,
  getList,
  getItem,
  meta: { touched, error, warning },
}) => {
  return (
    <React.Fragment>
      <FilterDropdown
        {...input}
        getList={getList}
        getItem={getItem}
        error={touched && error}
        onChange={(value) => input.onChange(value)}
      />
      {touched &&
        ((error && <ErrorBlock>{error}</ErrorBlock>) ||
          (warning && <WarningBlock>{warning}</WarningBlock>))}
    </React.Fragment>
  )
}

export const renderInput = ({
  input,
  type,
  min,
  max,
  step,
  placeholder,
  prepend,
  append,
  bsSize,
  meta: { touched, error, warning },
}) => {
  if (prepend) {
    prepend = <InputGroupAddon addonType="prepend">{prepend}</InputGroupAddon>
  }
  if (append) {
    if (type === 'number' && input.value > 1) {
      append = append.plural
    } else {
      append = append.singular
    }
    append = <InputGroupAddon addonType="append">{append}</InputGroupAddon>
  }
  return (
    <React.Fragment>
      <InputGroup>
        {prepend}
        <Input
          type={type}
          min={min}
          max={max}
          step={step}
          placeholder={placeholder}
          autoComplete="nope" // Chrome will ignore autoComplete="off", so we need to set a random string for it to prevent autocomplete.
          {...input}
          bsSize={bsSize}
          invalid={!!(touched && error)}
        />
        {append}
      </InputGroup>
      {touched &&
        ((error && <ErrorBlock>{error}</ErrorBlock>) ||
          (warning && <WarningBlock>{warning}</WarningBlock>))}
    </React.Fragment>
  )
}

export const renderSelect = ({
  input,
  options,
  labelKey,
  valueKey,
  placeholder,
  disabled,
  meta: { touched, error, warning },
}) => {
  return (
    <React.Fragment>
      <Select
        {...input}
        placeholder={placeholder || 'Kies…'}
        onBlur={() => {
          return input.onBlur(input.value)
        }}
        style={{
          borderColor: touched && error ? '#dc3545' : null,
        }}
        options={options}
        labelKey={labelKey}
        valueKey={valueKey}
        simpleValue
        disabled={disabled}
      />
      {touched &&
        ((error && <ErrorBlock>{error}</ErrorBlock>) ||
          (warning && <WarningBlock>{warning}</WarningBlock>))}
    </React.Fragment>
  )
}

export const renderRichTextArea = ({
  input,
  label,
  type,
  maxlength,
  meta: { touched, error, warning },
  ...props
}) => (
  <React.Fragment>
    <RichTextArea
      {...props}
      {...input}
      maxlength={maxlength}
      invalid={!!(touched && error)}
    />
    {touched &&
      ((error && <ErrorBlock>{error}</ErrorBlock>) ||
        (warning && <WarningBlock>{warning}</WarningBlock>))}
  </React.Fragment>
)

export const StyledSelectWrapper = styled.div`
  border: ${(props) => (props.error ? '1px solid #dc3545' : null)};
  padding-top: ${(props) => (props.error ? '2px' : null)};
`

export const StyledSelectList = styled(SelectList)`
  &.rw-select-list,
  &.rw-widget-container.rw-state-focus,
  &.rw-widget-container.rw-state-focus:hover {
    box-shadow: none;
    border: none;
    width: 100%;
  }

  ul {
    column-count: 2;
  }

  li {
    &.rw-state-focus,
    &.rw-state-focus:hover,
    :hover {
      background-color: transparent;
      border-color: transparent;
    }

    /*
    This works in Chrome, but not in FireFox:

    display: flex;
    break-inside: avoid-column;
    */

    /* This works in both FireFox and Chrome */
    display: table;
  }

  label {
    position: relative;

    &:before,
    &:after {
    }

    &:before {
      content: '';
      border-radius: 3px;
      background-color: ${(props) => props.theme.gray300};
      position: absolute;
      height: 1rem;
      width: 1rem;
      top: 0.25rem;
      left: 0;
      display: block;
      pointer-events: none;
    }

    [type='checkbox'] {
      display: none;
    }
  }

  [aria-checked='true'] {
    label:before {
      background-color: ${(props) => props.theme.secondary};
    }
    label:after {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
      position: absolute;
      top: 0.25rem;
      left: 0;
      display: block;
      width: 1rem;
      height: 1rem;
      content: '';
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }
  }
`

export const renderSelectList = ({
  input,
  data,
  valueField,
  textField,
  multiple,
  itemComponent,
  meta: { touched, error, warning },
}) => (
  <ErrorWrapper
    hasError={touched && (error || warning)}
    error={error}
    warning={warning}
  >
    <StyledSelectWrapper>
      <StyledSelectList
        {...input}
        onChange={(values) =>
          input.onChange(
            values.filter((value) => value.id).map((value) => value.id),
          )
        }
        onBlur={() => input.onBlur()}
        data={data}
        multiple={multiple}
        valueField={valueField}
        textField={textField}
        itemComponent={itemComponent}
      />
    </StyledSelectWrapper>
  </ErrorWrapper>
)
