import * as api from './api'

export const auth = {
  state: {
    me: null,
    loaded: false,
  },

  reducers: {
    setInfo(state, payload) {
      return {
        me: { ...(state.me || {}), ...payload },
        loaded: true,
      }
    },

    clearInfo(state) {
      return {
        me: null,
        loaded: true,
      }
    },
  },

  selectors: {
    isLoggedIn(state) {
      return state.me !== null
    },
  },

  effects: (dispatch) => ({
    async checkSession(payload, rootState) {
      try {
        const { data } = await api.getCurrentUser()
        dispatch.auth.setInfo(data)
      } catch (error) {
        console.debug('checkSession rejected', error)
        dispatch.auth.clearInfo()
      }
    },

    async login(payload, rootState) {
      const { data } = await api.login(payload)
      dispatch.auth.setInfo(data)
    },

    async logout(payload, rootState) {
      await api.logout()
      dispatch.auth.clearInfo()
    },
  }),
}

export const constants = {
  state: {
    countries: [],
    organisations: [],
    vacancies: {},
  },

  reducers: {
    setData(state, { key, payload }) {
      return {
        ...state,
        [key]: payload,
      }
    },
  },

  effects: (dispatch) => {
    return {
      async loadCountries(key, rootState) {
        const { data } = await api.loadCountries()
        dispatch.constants.setData({ key: 'countries', payload: data })
      },

      async loadOrganisations(key, rootState) {
        const { data } = await api.loadOrganisationConstants()
        dispatch.constants.setData({ key: 'organisations', payload: data })
      },

      async loadVacancies(key, rootState) {
        const { data } = await api.loadVacancyConstants()
        dispatch.constants.setData({ key: 'vacancies', payload: data })
      },
    }
  },
}
