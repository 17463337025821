import { debounce } from 'lodash'

import React from 'react'
import { DropdownList } from 'react-widgets'
import styled from 'styled-components'

import IconButton from './form/IconButton'

const StyledDropdownList = styled(DropdownList)`
  flex-basis: 100%;

  .rw-widget-container.rw-widget-picker:not(.rw-filter-input) {
    border: ${(props) =>
      props.error ? '1px solid #dc3545' : '1px solid #ced4da'};
    box-shadow: none;
  }
`

const StyledIconbutton = styled(IconButton)`
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  right: 45px;
  z-index: 1;
`

class FilterDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      selectedValue: null,
    }

    this.clearSelected = this.clearSelected.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSearch = debounce(this.handleSearch.bind(this), 300)
  }

  async componentDidMount() {
    const { data } = await this.props.getList()

    this.updateListState(data.results)

    if (this.props.value) {
      this.setName(this.props.value)
    }
  }

  async handleSearch(searchTerm) {
    const { data } = await this.props.getList({
      params: { search: searchTerm },
    })

    this.updateListState(data.results)
  }

  async setName(id) {
    /*
      Based on the field value received via props (Id),
      it's necessary to find out the the item name to be displayed
    */
    const item = await this.props.getItem(id)
    if (item) {
      this.setState({ selectedValue: item.name })
    }
  }

  updateListState(data) {
    /*
      Extract only the key/values that we want from the
      response (id and name) and set them into the state
    */
    const cleanList = data.map((item) => {
      return {
        id: item.id,
        name: item.name,
      }
    })
    this.setState({ data: cleanList })
  }

  clearSelected() {
    this.setState({ selectedValue: null })
    this.props.onChange(null)
  }

  handleChange(value) {
    this.setState({ selectedValue: value.name })
    this.props.onChange(value.id)
  }

  render() {
    const { error } = this.props

    return (
      <React.Fragment>
        <StyledDropdownList
          error={error}
          filter={() => this.state.data}
          data={this.state.data}
          value={this.state.selectedValue}
          textField="name"
          valueField="id"
          onChange={this.handleChange}
          onSearch={this.handleSearch}
        />
        {this.state.selectedValue && (
          <StyledIconbutton
            color="link"
            icon="times"
            onClick={this.clearSelected}
            height="25px"
            width="10px"
          />
        )}
      </React.Fragment>
    )
  }
}

export default FilterDropdown
