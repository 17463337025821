import React from 'react'
import { Button, UncontrolledTooltip as Tooltip } from 'reactstrap'

import styled from 'styled-components'

import Icon from '../Icon'

const StyledButton = styled(Button)`
  padding: 0;
  height: 30px;
  width: 30px;
`

const StyledIcon = styled(Icon)`
  display: inline-block;
  height: 20px;
  width: 20px;
`

const Wrapper = styled.div`
  text-align: center;
`

function generateId() {
  return Math.random().toString(36).substr(2)
}

function ActionButtons({ onDuplicate, onEdit }) {
  const id = generateId()
  return (
    <Wrapper>
      {onEdit && (
        <>
          <Tooltip delay={{ show: 400 }} target={`__edit__${id}`}>
            Aanpassen
          </Tooltip>
          <StyledButton color="light" onClick={onEdit} id={`__edit__${id}`}>
            <StyledIcon icon="edit" />
          </StyledButton>
        </>
      )}
      {onDuplicate && (
        <>
          <Tooltip delay={{ show: 400 }} target={`__duplicate__${id}`}>
            Dupliceren
          </Tooltip>
          <StyledButton
            color="light"
            onClick={onDuplicate}
            id={`__duplicate__${id}`}
          >
            <StyledIcon icon="duplicate" />
          </StyledButton>
        </>
      )}
    </Wrapper>
  )
}

export default ActionButtons
