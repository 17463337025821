import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'

import { select } from '../index'

import NavLink from './NavLink'

const AuthButton = ({ isLoggedIn, loading, me, logOut }) =>
  loading ? (
    <Skeleton width={66} />
  ) : isLoggedIn ? (
    <Button size="sm" outline onClick={logOut}>
      Log uit
    </Button>
  ) : (
    <NavLink to="/login">Log in</NavLink>
  )

const mapState = (state) => ({
  isLoggedIn: select.auth.isLoggedIn(state),
  loading: state.loading.models.auth,
})

const mapDispatch = (dispatch) => ({
  logOut: dispatch.auth.logout,
})

export default connect(mapState, mapDispatch)(AuthButton)
