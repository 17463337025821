import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'

import FormField from '../FormField'
import FunctionTypeSelect from '../FunctionTypeSelect'
import RadioSelect from '../RadioSelect'
import { renderSelectList, renderRichTextArea } from '../renderers'
import withFormCard from './withFormCard'

function EducationLevelSelect({ data }) {
  return (
    <Row>
      <Col>
        <RadioSelect
          name="education_level"
          choiceList={data.slice(0, data.length / 2)}
        />
      </Col>
      <Col>
        <RadioSelect
          name="education_level"
          choiceList={data.slice(data.length / 2)}
        />
      </Col>
    </Row>
  )
}

class Requirements extends Component {
  componentDidMount() {
    const {
      dispatch,
      educationLevelChoices,
      functionTypeChoices,
      scientificFieldChoices,
    } = this.props
    if (
      !educationLevelChoices.length ||
      !functionTypeChoices.length ||
      !scientificFieldChoices.length
    ) {
      dispatch.constants.loadVacancies()
    }
  }

  render() {
    const {
      initialValues,
      educationLevelChoices,
      functionTypeChoices,
      scientificFieldChoices,
    } = this.props
    return (
      <>
        <FormField
          component={renderRichTextArea}
          label="Functie-eisen"
          name="translations[0].requirements"
          type="textarea"
          maxlength="500"
        />
        <FormField
          component={FunctionTypeSelect}
          label="Functietype"
          name="function_types"
          data={functionTypeChoices}
          valueField="id"
          textField="label"
          helpText="Er zijn twee soorten vacatures: wetenschappelijke (WP) en niet-wetenschappelijke (OBP). Gebruik deze niet beiden voor één functie. Alleen wetenschappelijke functies delen wij op onze social media en met partners zoals NRC en Euraxess."
        />
        <FormField
          label="Wetenschappelijke discipline"
          name="scientific_fields"
          data={scientificFieldChoices}
          valueField="id"
          textField="label"
          multiple
          component={renderSelectList}
          optional
          isOpen={!!initialValues.scientific_fields}
          helpText="Alleen van toepassing op wetenschappelijke functies."
        />
        <FormField
          label="Opleidingsniveau"
          name="education_level"
          data={educationLevelChoices}
          optional
          isOpen={!!initialValues.education_level}
          component={EducationLevelSelect}
        />
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    educationLevelChoices:
      state.constants.vacancies.education_level_choices || [],
    functionTypeChoices: state.constants.vacancies.function_type_choices || [],
    scientificFieldChoices:
      state.constants.vacancies.scientific_field_choices || [],
  }
}

export default connect(mapStateToProps)(
  withFormCard(Requirements, {
    label: 'Vereisten',
    htmlId: 'requirements_header',
    fields: [
      'requirements',
      'function_types',
      'scientific_fields',
      'education_level',
    ],
  }),
)
