import React from 'react'
import { Button, Input } from 'reactstrap'
import styled from 'styled-components'

import IconButton from '../form/IconButton'

const StyledButton = styled(Button)`
  margin-left: 15px;
`

const StyledIconbutton = styled(IconButton)`
  margin-left: -20px;
  z-index: 1;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: '',
    }

    this.clearSearch = this.clearSearch.bind(this)
  }

  clearSearch() {
    this.props.onClear()
  }

  handleKeyUp = (evt) => {
    if (evt.key === 'Enter') {
      this.props.onSearch(evt.target.value)
    }
  }

  render() {
    const { onSearch } = this.props

    return (
      <React.Fragment>
        <Input
          type="search"
          name="search"
          placeholder="Vacature titel en tekst"
          onChange={(evt) => this.setState({ value: evt.target.value })}
          onKeyUp={(evt) => this.handleKeyUp(evt)}
          value={this.state.value}
        />
        {this.state.value && (
          <StyledIconbutton
            color="link"
            icon="times"
            onClick={(evt) => {
              this.setState({ value: '' })
              onSearch(false)
            }}
            height="25px"
            width="10px"
          />
        )}
        <StyledButton
          color="secondary"
          onClick={(evt) => onSearch(this.state.value)}
        >
          Zoeken
        </StyledButton>
      </React.Fragment>
    )
  }
}
