import React from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { change, getFormSubmitErrors, reduxForm } from 'redux-form'

import SelfDismissingAlert from '../SelfDismissingAlert'
import StickyNotification from './StickyNotification'
import StickySideNav from './StickySideNav'
import * as cards from './VacancyFormCards'

class VacancyForm extends React.Component {
  constructor() {
    super()
    this.state = {
      navItems: {},
    }
    this.addToNav = this.addToNav.bind(this)
    this.updateNavErrorCount = this.updateNavErrorCount.bind(this)
  }

  addToNav(key, item) {
    this.setState((state) => {
      return { navItems: { ...state.navItems, [key]: item } }
    })
  }

  updateNavErrorCount(key, count) {
    this.setState((state) => {
      return {
        navItems: {
          ...state.navItems,
          [key]: {
            ...state.navItems[key],
            errors: count,
          },
        },
      }
    })
  }

  render() {
    const {
      changeFieldValue,
      error,
      initialValues,
      id,
      isDraft,
      isTemplate,
      submitting,
      submitErrors,
      submitFailed,
      submitRow,
      submitSucceeded,
      title,
      user,
    } = this.props

    const cardProps = {
      changeFieldValue,
      initialValues,
      id,
      isDraft,
      isTemplate,
      submitErrors,
      user,
      addToNav: this.addToNav,
      updateNavErrorCount: this.updateNavErrorCount,
    }

    const submitFailedNotification = !submitting && submitFailed && (
      <SelfDismissingAlert>
        <StickyNotification color="danger">
          {error ||
            'De actie is mislukt: los de gemelde issues in het formulier op en probeer opnieuw'}
        </StickyNotification>
      </SelfDismissingAlert>
    )

    const submitSuccessNotification = !submitting && submitSucceeded && (
      <SelfDismissingAlert>
        <StickyNotification color="success">
          Succes: de wijzigingen zijn opgeslagen
        </StickyNotification>
      </SelfDismissingAlert>
    )

    return (
      <>
        {submitFailedNotification}
        {submitSuccessNotification}
        <Row>
          <Col xs={3}>
            <h4>{title}</h4>
          </Col>
          <Col xs={9}>{submitRow}</Col>
        </Row>
        <Row id="vacancy-form-wrapper">
          <Col xs={3}>
            <StickySideNav navitems={Object.values(this.state.navItems)} />
          </Col>
          <Col xs={9}>
            <cards.GeneralInformation {...cardProps} />
            {user.is_at_user && <cards.DashboardAccess {...cardProps} />}
            <cards.Description {...cardProps} />
            <cards.Requirements {...cardProps} />
            <cards.ContractTerms {...cardProps} />
            <cards.Organisation {...cardProps} />
            <cards.MiscInformation {...cardProps} />
            <cards.Application {...cardProps} />
          </Col>
        </Row>
        <Row>
          <Col xs={3} />
          <Col xs={9}>
            <hr />
            {submitRow}
          </Col>
        </Row>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    submitErrors: getFormSubmitErrors('vacancyForm')(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // Helper function to change a field value in the redux form.
    // It is used by the location lookup to change address information.
    changeFieldValue(field, value) {
      dispatch(change('vacancyForm', field, value))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'vacancyForm',
  })(VacancyForm),
)
