import React from 'react'
import styled from 'styled-components'

const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0);
  opacity: 0.6;
  color: white;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  &.overlay-appear,
  &.overlay-enter,
  &.overlay-leave.overlay-leave-active {
    opacity: 0.01;
  }

  &.overlay-appear.overlay-appear-active,
  &.overlay-enter.overlay-enter-active,
  &.overlay-leave:not(.overlay-leave-active) {
    opacity: 0.6;
  }
`

const BlurredDiv = styled.div`
  filter: ${(props) => (props.blur ? 'blur(1px)' : 'none')};
`

const Loadable = ({ active, children, text }) => (
  <React.Fragment>
    {active && (
      <StyledOverlay key="overlay">
        <span>{text || 'Loading...'}</span>
      </StyledOverlay>
    )}
    {
      <BlurredDiv key="content" blur={active}>
        {children}
      </BlurredDiv>
    }
  </React.Fragment>
)

export default Loadable
