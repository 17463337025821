import { get as getSafe } from 'lodash'
import React from 'react'
import { FormGroup, Label } from 'reactstrap'

import CheckboxShowField from '../CheckboxShowField'
import { ErrorWrapper } from '../ErrorWrapper'
import FormField from '../FormField'
import { renderInput, renderRichTextArea } from '../renderers'
import withFormCard from './withFormCard'

function Application({ initialValues, submitErrors }) {
  return (
    <>
      <FormField
        label="Sollicitatieprocedure"
        name="translations[0].extra_info"
        type="textarea"
        component={renderRichTextArea}
        optional
        isOpen={!!getSafe(initialValues, 'translations[0].extra_info')}
      />
      <FormGroup>
        <Label>Sollicitatiemogelijkheden</Label>
        <ErrorWrapper
          hasError={!!getSafe(submitErrors, 'translations[0].application_mode')}
          error={getSafe(submitErrors, 'translations[0].application_mode')}
          warning={false}
        >
          <FormField
            inline
            label="Sollicitaties via e-mail"
            name="translations[0].application_email"
            type="email"
            component={renderInput}
            helpText="Vul het e-mailadres in waarop alle sollicitaties binnen moeten komen."
          />

          <FormField
            inline
            label="Sollicitaties via URL"
            name="translations[0].application_url"
            type="text"
            component={renderInput}
            helpText="Vul de link in naar je eigen sollicitatieformulier of ats (applicant tracking system)."
          />

          <CheckboxShowField
            id="check_application_post"
            name="check_application_post"
            label="Sollicitaties via post"
            isOpen={
              !!getSafe(initialValues, 'translations[0].application_address')
            }
          >
            <FormField
              inline
              label="Postadres"
              type="textarea"
              component={renderInput}
              name="translations[0].application_address"
            />
          </CheckboxShowField>
        </ErrorWrapper>
      </FormGroup>
    </>
  )
}

export default withFormCard(Application, {
  label: 'Sollicitatie',
  htmlId: 'application_header',
  fields: [
    'extra_info',
    'application_mode',
    'application_email',
    'application_url',
    'application_address',
  ],
})
