import React from 'react'

const icons = {
  archive: {
    content: (props) => (
      <path
        fill={props.color || '#3b3a3a'}
        fillOpacity={props.opacity || '.6'}
        d="M32 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V160H32v288zm160-212c0-6.6 5.4-12 12-12h104c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-8zM480 32H32C14.3 32 0 46.3 0 64v48c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16V64c0-17.7-14.3-32-32-32z"
      />
    ),
    viewbox: '0 0 512 512',
    origin: 'Fontawesome license: https://fontawesome.com/license',
  },
  check: {
    content: (props) => (
      <path
        d="M8.63 11.567l4.474-8.28 2.64 1.426-6.39 11.822L2.94 10.12 5.061 8z"
        fill={props.color || '#c4c3c3'}
      />
    ),
    viewbox: '0 0 20 20',
  },
  delete: {
    content: (props) => (
      <path
        d="M6 15V7h9v8c0 1.293-.675 2-1 2H8c-1.325 0-2-.707-2-2zm7-11h3v2H5V4h3l1-1h3zm-1.646 7.646L13.12 9.88l-.707-.707-1.768 1.767L8.88 9.172l-.707.707 1.767 1.767-1.767 1.768.707.707 1.767-1.767 1.768 1.767.707-.707z"
        fill={props.color || '#40bcd7'}
      />
    ),
    viewbox: '0 0 20 20',
  },
  draft: {
    content: (props) => (
      <path
        fill={props.color || '#3b3a3a'}
        fillOpacity={props.opacity || '.6'}
        d="M218.17 424.14c-2.95-5.92-8.09-6.52-10.17-6.52s-7.22.59-10.02 6.19l-7.67 15.34c-6.37 12.78-25.03 11.37-29.48-2.09L144 386.59l-10.61 31.88c-5.89 17.66-22.38 29.53-41 29.53H80c-8.84 0-16-7.16-16-16s7.16-16 16-16h12.39c4.83 0 9.11-3.08 10.64-7.66l18.19-54.64c3.3-9.81 12.44-16.41 22.78-16.41s19.48 6.59 22.77 16.41l13.88 41.64c19.75-16.19 54.06-9.7 66 14.16 1.89 3.78 5.49 5.95 9.36 6.26v-82.12l128-127.09V160H248c-13.2 0-24-10.8-24-24V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24v-40l-128-.11c-16.12-.31-30.58-9.28-37.83-23.75zM384 121.9c0-6.3-2.5-12.4-7-16.9L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1zm-96 225.06V416h68.99l161.68-162.78-67.88-67.88L288 346.96zm280.54-179.63l-31.87-31.87c-9.94-9.94-26.07-9.94-36.01 0l-27.25 27.25 67.88 67.88 27.25-27.25c9.95-9.94 9.95-26.07 0-36.01z"
      />
    ),
    viewbox: '0 0 512 512',
    origin: 'Fontawesome license: https://fontawesome.com/license',
  },
  duplicate: {
    content: (props) => (
      <g fill={props.color || '#40bcd7'} transform="translate(4 3)">
        <path d="m5 5v7h5v-7zm0-2h5c1.1045695 0 2 .8954305 2 2v7c0 1.1045695-.8954305 2-2 2h-5c-1.1045695 0-2-.8954305-2-2v-7c0-1.1045695.8954305-2 2-2z" />
        <path d="m2 5.85068992v-3.85068992h3.07461145l1.59691458-2h-4.67152603c-1.1045695 0-2 .8954305-2 2v6.3555202zm0 0v-3.85068992h3.07461145l1.59691458-2h-4.67152603c-1.1045695 0-2 .8954305-2 2v6.3555202z" />
      </g>
    ),
    viewBox: '0 0 20 20',
  },
  edit: {
    content: (props) => (
      <path
        d="M4 13.531V16h2.469L13 9.469 10.531 7zm11.8-6.777a.678.678 0 0 0 0-.96l-1.593-1.595a.678.678 0 0 0-.96 0L12 5.446 14.554 8z"
        fill={props.color || '#40bcd7'}
      />
    ),
    viewbox: '0 0 20 20',
  },
  eye: {
    content: (props) => (
      <path
        fill={props.color || '#C4C3C3'}
        fillRule="nonzero"
        d="M10 .75C5.83333333.75 2.275 3.34166667.83333333 7 2.275 10.6583333 5.83333333 13.25 10 13.25c4.1666667 0 7.725-2.5916667 9.1666667-6.25C17.725 3.34166667 14.1666667.75 10 .75zm0 10.4166667C7.7 11.1666667 5.83333333 9.3 5.83333333 7 5.83333333 4.7 7.7 2.83333333 10 2.83333333S14.1666667 4.7 14.1666667 7 12.3 11.1666667 10 11.1666667zM10 4.5C8.61666667 4.5 7.5 5.61666667 7.5 7c0 1.3833333 1.11666667 2.5 2.5 2.5 1.3833333 0 2.5-1.1166667 2.5-2.5 0-1.38333333-1.1166667-2.5-2.5-2.5z"
      />
    ),
    viewbox: '0 0 20 14',
    origin: 'from design',
  },
  compact_view: {
    content: (props) => (
      <path
        fill={props.color || '#3b3a3a'}
        fillOpacity={props.opacity || '.6'}
        d="M0 0h12v1H0zM0 11h12v1H0zm0-8h12v6H0z"
      />
    ),
    viewbox: '0 0 12 12',
    origin: 'from design',
  },
  full_view: {
    content: (props) => (
      <g
        fill={props.color || '#3b3a3a'}
        fillOpacity={props.opacity || '.6'}
        fillRule="evenodd"
      >
        <path d="M0 0h12v2H0zM0 9h12v2H0zM0 3h12v2H0zM0 6h12v2H0z" />
      </g>
    ),
    viewbox: '0 0 12 11',
    origin: 'from design',
  },
  gb_flag: {
    content: (props) => (
      <React.Fragment>
        <path
          fill="#006"
          d="M308 225.7V252h240V105.7zM948-201.7V-228H708v146.3zM308 92v26.3L360.7 92zM308-94.3V-68h52.7zM548-81.7V-228H308v26.3zM948 118.3V92h-52.7zM948-68v-26.3L895.3-68zM708 252h240v-26.3l-240-120z"
        />
        <path
          fill="#FFF"
          d="M859.6 92l88.4 44.2v-17.9L895.3 92zM708 92v13.7l240 120V172L788 92zM396.5-68L308-112.2v17.9L360.7-68zM895.3-68L948-94.3V-148L788-68zM548 92h-8.4L308 207.8v17.9l240-120zM708-68h8.5L948-183.8v-17.9l-240 120zM360.7 92L308 118.3V172l160-80zM548-68v-13.7l-240-120v53.7l160 80z"
        />
        <path
          fill="#FFF"
          d="M580 60H308v32h240v160h32zM676-36h272v-32H708v-160h-32zM548-68H308v32h272v-192h-32v146.3zM676 60v192h32V92h240V60z"
        />
        <path
          fill="#C00"
          d="M580-36H308v96h272v192h96V60h272v-96H676v-192h-96z"
        />
        <path
          fill="#C00"
          d="M788-68l160-80v-35.8L716.5-68zM468 92l-160 80v35.8L539.6 92zM788 92l160 80v-35.8L859.6 92zM468-68l-160-80v35.8L396.5-68z"
        />
      </React.Fragment>
    ),
    viewbox: '308 -228 640 480',
    origin:
      'from wikipedia (https://en.wikipedia.org/wiki/Flag_of_the_United_Kingdom#/media/File:Flag_of_the_United_Kingdom.svg)',
  },
  help_circled: {
    content: (props) => (
      <path
        fill={props.color || '#C4C3C3'}
        fillRule="nonzero"
        d="M8 0C3.58214286 0 0 3.58214286 0 8c0 4.4178571 3.58214286 8 8 8 4.4178571 0 8-3.5821429 8-8 0-4.41785714-3.5821429-8-8-8zm.72142857 11.6678571c-.01785714.6357143-.48928571 1.0285715-1.1 1.0107143-.58571428-.0178571-1.04642857-.4357143-1.02857143-1.075.01785715-.6357143.50357143-1.0392857 1.08928572-1.0214285.61428571.0178571 1.06071428.45 1.03928571 1.0857142zm1.73928573-4.54642853c-.15.21071429-.4857143.48214286-.90714287.81071429l-.46785714.32142857c-.22857143.175-.37142858.38214286-.44642858.61785714-.03928571.125-.06785714.45-.075.66785714-.00357142.04285715-.02857142.13928572-.16071428.13928572H7.00714286c-.14642857 0-.16428572-.08571429-.16071429-.12857143.02142857-.59285714.10714286-1.08214286.35357143-1.475.33214286-.52857143 1.26785714-1.08571429 1.26785714-1.08571429.14285715-.10714285.25357143-.22142857.33928572-.34642857.15714285-.21428571.28571428-.45357143.28571428-.71071428 0-.29642857-.07142857-.57857143-.26071428-.81428572-.22142857-.275-.46071429-.40714285-.92142857-.40714285-.45357143 0-.71785715.22857142-.90714286.52857142-.18928572.3-.15714286.65357143-.15714286.975H5.10714286C5.10714286 5 5.425 4.225 6.09642857 3.76785714 6.55 3.45714286 7.12857143 3.32142857 7.80357143 3.32142857c.88571428 0 1.58928571.16428572 2.21071427.63571429.575.43571428.8785714 1.05.8785714 1.87857143 0 .51071428-.1785714.93928571-.4321428 1.28571428z"
      />
    ),
    viewbox: '0 0 16 16',
    origin: 'from design',
  },
  importFalse: {
    content: (props) => (
      <path
        fill={props.color || '#C4C3C3'}
        d="M8.117 9.117l4.202 8.489-1.601.83L9.512 16h-5.09C3.643 16 3 15.308 3 14.512V6.488c0-.679.468-1.282 1.089-1.445L3.18 3.209l1.601-.829L8.059 9h2.144V3H12v6h2l-3 3-2.883-2.883zM8.522 14L5.058 7H5v7h3.522zm4.99 2l-.989-2H15V7h-2V5h2.544C16.323 5 17 5.692 17 6.488v8.024c0 .796-.643 1.488-1.456 1.488h-2.032z"
        fillRule="evenodd"
      />
    ),
    viewbox: '0 0 20 20',
  },
  importTrue: {
    content: (props) => (
      <path
        fill={props.color || '#C4C3C3'}
        d="M17 14.512c0 .796-.643 1.488-1.456 1.488H4.422C3.643 16 3 15.308 3 14.512V6.488C3 5.692 3.643 5 4.422 5H7v2H5v7h10V7h-2V5h2.544C16.323 5 17 5.692 17 6.488zM7.107 9h2.202V3h1.798v6h2l-3 3z"
      />
    ),
    viewbox: '0 0 20 20',
  },
  map: {
    content: (props) => (
      <path
        fill="currentColor"
        d="M288 0c-69.59 0-126 56.41-126 126 0 56.26 82.35 158.8 113.9 196.02 6.39 7.54 17.82 7.54 24.2 0C331.65 284.8 414 182.26 414 126 414 56.41 357.59 0 288 0zm0 168c-23.2 0-42-18.8-42-42s18.8-42 42-42 42 18.8 42 42-18.8 42-42 42zM20.12 215.95A32.006 32.006 0 0 0 0 245.66v250.32c0 11.32 11.43 19.06 21.94 14.86L160 448V214.92c-8.84-15.98-16.07-31.54-21.25-46.42L20.12 215.95zM288 359.67c-14.07 0-27.38-6.18-36.51-16.96-19.66-23.2-40.57-49.62-59.49-76.72v182l192 64V266c-18.92 27.09-39.82 53.52-59.49 76.72-9.13 10.77-22.44 16.95-36.51 16.95zm266.06-198.51L416 224v288l139.88-55.95A31.996 31.996 0 0 0 576 426.34V176.02c0-11.32-11.43-19.06-21.94-14.86z"
      />
    ),
    viewbox: '0 0 576 512',
  },
  nl_flag: {
    content: (props) => (
      <React.Fragment>
        <path fill="#21468B" d="M0 0h9v6H0z" />
        <path fill="#FFF" d="M0 0h9v4H0z" />
        <path fill="#AE1C28" d="M0 0h9v2H0z" />
      </React.Fragment>
    ),
    viewbox: '0 0 9 6',
    origin:
      'from wikipedia (https://en.wikipedia.org/wiki/Flag_of_the_Netherlands#/media/File:Flag_of_the_Netherlands.svg)',
  },
  plus: {
    content: (props) => (
      <g
        fill={props.color || '#40BCD7'}
        fillRule="evenodd"
        opacity={props.opacity || '.6'}
      >
        <path d="M4 0h2v10H4z" />
        <path d="M10 4v2H0V4z" />
      </g>
    ),
    viewbox: '0 0 10 10',
    origin: 'from design',
  },
  published: {
    content: (props) => (
      <path
        fill={props.color || '#3b3a3a'}
        fillOpacity={props.opacity || '.6'}
        d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 236c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-72v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm96-114.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
      />
    ),
    viewbox: '0 0 512 512',
    origin: 'Fontawesome license: https://fontawesome.com/license',
  },
  times: {
    content: (props) => (
      <path
        fill={props.color || '#3b3a3a'}
        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
      />
    ),
    viewbox: '0 0 352 512',
    origin: 'Fontawesome license: https://fontawesome.com/license',
  },
}

export default class Icon extends React.Component {
  render() {
    const { icon, ...props } = this.props

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
        aria-label={icon + ' icon'}
        role="img"
        viewBox={icons[icon].viewbox}
        {...props}
      >
        {/* Not output as a valid comment but at least does show in the markup and not on the frontend. */}
        {(icons[icon].origin && `<!--${icons[icon].origin}-->`) || ''}
        {icons[icon].content(props)}
      </svg>
    )
  }
}
