import { default as _axios } from 'axios'

const axios = _axios.create({
  baseURL: '/api/v1',
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
})

// Auth

export async function getCurrentUser() {
  return await axios.get('auth/me/')
}

export async function login(data) {
  return await axios.post('/auth/session/', data)
}

export async function logout() {
  return await axios.delete('/auth/session/')
}

// Constants

export async function loadCountries() {
  return await axios.get('constants/countries/')
}

export async function loadOrganisationConstants() {
  return await axios.get('constants/organisations/')
}

export async function loadVacancyConstants() {
  return await axios.get('constants/vacancies/')
}

// Draft vacancies

export async function listDraftVacancies(options) {
  return await axios.get('vacancies/draft/', options)
}

export async function getDraftVacancy(id) {
  return await axios.get(`vacancies/draft/${id}/`)
}

export async function saveDraftVacancy(id = null, data) {
  const draftData = {
    data,
    // Template fields are part of the Draft model
    // and need to be extracted from the data object
    is_template: data.is_template,
    template_title: data.template_title,
  }
  delete draftData.data.is_template
  delete draftData.data.template_title
  if (id) {
    return await axios.put(`vacancies/draft/${id}/`, draftData)
  } else {
    return await axios.post(`vacancies/draft/`, draftData)
  }
}

export async function deleteDraftVacancy(id) {
  return await axios.delete(`/vacancies/draft/${id}/`)
}

export async function publishDraftVacancy(id) {
  return await axios.post(`vacancies/draft/${id}/publish/`)
}

// Published vacancies

export async function listPublishedVacancies(options) {
  return await axios.get('vacancies/published/', options)
}

export async function getPublishedVacancy(id) {
  return await axios.get(`vacancies/published/${id}/`)
}

export async function savePublishedVacancy(id, data) {
  return await axios.post(`vacancies/published/${id}/publish/`, { data })
}

export async function archivePublishedVacancy(id) {
  return await axios.post(`/vacancies/published/${id}/archive/`)
}

export async function deletePublishedVacancy(id) {
  return await axios.delete(`/vacancies/published/${id}/`)
}

// Organisations

export async function getOrganisationsList(options) {
  return await axios.get('organisations/', options)
}

export async function getOrganisation(id) {
  const { data } = await axios.get('organisations/', { params: { id } })
  if (data.results.length) {
    return data.results[0]
  } else {
    return null
  }
}

// Media partners

export async function getMediaPartnersList(options) {
  return await axios.get('vacancies/mediapartners/', options)
}

export async function getMediaPartner(id) {
  const { data } = await axios.get('vacancies/mediapartners/', {
    params: { id },
  })
  if (data.results.length) {
    return data.results[0]
  } else {
    return null
  }
}

// Projects

export async function getProjectsList(options) {
  return await axios.get('vacancies/projects/', options)
}

export async function getProject(id) {
  const { data } = await axios.get('vacancies/projects/', { params: { id } })
  if (data.results.length) {
    return data.results[0]
  } else {
    return null
  }
}
