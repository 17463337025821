import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormFeedback,
  FormGroup,
  Input,
} from 'reactstrap'
import { Field, reduxForm, SubmissionError } from 'redux-form'

import { required } from '../utils/validationUtils'

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <FormGroup>
    <Input
      type={type}
      {...input}
      placeholder={label}
      invalid={!!(touched && error)}
    />
    {touched && error && <FormFeedback invalid="invalid">{error}</FormFeedback>}
  </FormGroup>
)

class _Login extends React.Component {
  state = {
    redirectToReferrer: false,
  }
  login() {
    this.setState({ redirectToReferrer: true })
  }

  render() {
    const { error, handleSubmit, submitting } = this.props
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    const { redirectToReferrer } = this.state

    if (redirectToReferrer) {
      return <Redirect to={from} />
    }

    return (
      <Card>
        <CardHeader>Log in</CardHeader>
        <CardBody>
          {error && <Alert color="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Field
              name="username"
              type="text"
              component={renderField}
              label="Gebruikersnaam"
              validate={[required]}
            />
            <Field
              name="password"
              type="password"
              component={renderField}
              label="Wachtwoord"
              validate={[required]}
            />
            <Button type="submit" disabled={submitting}>
              Log in
            </Button>
          </Form>
        </CardBody>
      </Card>
    )
  }
}

export default withRouter(
  reduxForm({
    form: 'login',
    onSubmit: async (values, dispatch, props) => {
      try {
        await dispatch.auth.login(values)
        const { from } = props.location.state || { from: { pathname: '/' } }
        props.history.push(from)
      } catch (error) {
        const { response } = error
        if (response && response.status === 401) {
          throw new SubmissionError({
            _error: response.data.non_field_errors[0],
          })
        } else {
          throw new SubmissionError({ _error: 'Er ging iets mis' })
        }
      }
    },
  })(_Login),
)
