import nl from 'date-fns/esm/locale/nl'
import parse from 'date-fns/parse'
import dateFnsLocalizer from 'react-widgets-date-fns'

dateFnsLocalizer({ locales: { nl: nl } })

export function isValidDate(date) {
  return date instanceof Date && !isNaN(date)
}

export function addDays(date, days) {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export function parseDate(value) {
  if (value instanceof Date) return value
  const formats = [
    "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
    "yyyy-MM-dd'T'HH:mm:ssXXX",
    'd-M-yyyy H:mm',
    'd-M-yyyy',
    'd-M',
    'd MMMM yyyy',
    'd MMMM',
  ]
  const today = new Date()
  today.setHours(0)
  today.setMinutes(0)
  today.setSeconds(0)
  today.setMinutes(0)
  today.setMilliseconds(0)
  let format, result
  for (let i = 0; i < formats.length; i++) {
    format = formats[i]
    result = parse(value, format, today, { locale: nl })
    if (!isNaN(result.getFullYear())) {
      result.setSeconds(0)
      result.setMilliseconds(0)
      break
    }
  }
  return result
}
