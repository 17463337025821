import 'react-table/react-table.css'

import format from 'date-fns/format'
import { get as getSafe, pickBy as filterObject } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import ReactTable from 'react-table'
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Container,
  Label,
  UncontrolledTooltip as Tooltip,
  Row,
} from 'reactstrap'
import styled from 'styled-components'

import IconButton from '../components/form/IconButton'
import ActionButtons from '../components/overview/ActionButtons'
import SearchInput from '../components/overview/SearchInput'
import Icon from '../components/Icon'
import FilterDropdown from '../components/FilterDropdown'
import SelfDismissingAlert from '../components/SelfDismissingAlert'
import * as api from '../data/api'

const modes = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  ARCHIVED: 'archived',
}
Object.freeze(modes)

const DateCell = ({ value }) => {
  return value ? (
    <span title={value}>{format(new Date(value), 'd-M-yyyy')}</span>
  ) : null
}

const StyledTable = styled(ReactTable)`
  &.ReactTable .rt-th,
  &.ReactTable .rt-td {
    padding: 5px 5px;
  }
`

const ExternalIdLink = styled.a`
  color: ${(props) => props.theme.secondary};

  &:hover {
    color: ${(props) => props.theme.info};
  }
`

const CenterAlign = styled.div`
  text-align: center;
`

const getColumns = (mode, refresh, history, user) => [
  /*
  The data of drafts has a different structure from that of published/archived vacancies.
 */
  {
    id: 'title',
    show: mode !== modes.DRAFT,
    accessor: 'translations[0].title',
    Header: 'Titel',
    headerClassName: 'title_header',
    className: 'title',
  },
  {
    id: 'draft_title',
    show: mode === modes.DRAFT,
    Header: 'Titel',
    headerClassName: 'title_header',
    className: 'title',
    Cell: ({ original }) =>
      original.is_template
        ? original.template_title
        : original.data.translations[0].title,
  },
  {
    id: 'template',
    show: mode === modes.DRAFT,
    Header: 'Template',
    width: 80,
    sortable: false,
    Cell: ({ original }) =>
      original.is_template && (
        <CenterAlign>
          <Icon icon="check" height="20px" />
        </CenterAlign>
      ),
  },
  {
    id: 'external_id',
    accessor: 'external_id',
    Header: 'ID',
    headerClassName: 'external_id_header',
    width: 90,
    show: mode !== modes.DRAFT,
    Cell: ({ original }) => (
      <ExternalIdLink
        color="link" // prop from reactstrap Button to disable button background-color
        href={original.short_url}
        target="_blank"
        rel="noopener"
      >
        {original.external_id}
      </ExternalIdLink>
    ),
  },
  {
    id: 'organisation',
    accessor: 'organisation',
    Header: 'Organisatie',
    width: 150,
    show: mode !== modes.DRAFT && user.is_at_user,
    sortable: false,
  },
  {
    id: mode === modes.DRAFT ? 'data__code' : 'code',
    accessor: mode === modes.DRAFT ? 'data.code' : 'code',
    Header: 'Vacaturenummer',
    headerClassName: 'code_header',
    className: 'code',
    width: 150,
  },
  {
    id: mode === modes.DRAFT ? 'data__start_date' : 'start_date',
    accessor: mode === modes.DRAFT ? 'data.start_date' : 'start_date',
    Header: 'Startdatum',
    headerClassName: 'start_date_header',
    className: 'start_date',
    width: 100,
    Cell: DateCell,
  },
  {
    id: mode === modes.DRAFT ? 'data__end_date' : 'end_date',
    accessor: mode === modes.DRAFT ? 'data.end_date' : 'end_date',
    Header: 'Einddatum',
    headerClassName: 'end_date_header',
    className: 'end_date',
    width: 100,
    Cell: DateCell,
  },
  {
    Header: 'Import',
    width: 60,
    show: mode !== modes.DRAFT,
    sortable: false,
    Cell: ({ original }) => (
      <CenterAlign>
        <Tooltip
          target={`__import__${original.external_id}`}
          delay={{ show: 200 }}
        >
          {original.include_in_import ? 'Uit import' : 'Handmatige invoer'}
        </Tooltip>
        <Icon
          icon={original.include_in_import ? 'importTrue' : 'importFalse'}
          id={`__import__${original.external_id}`}
          height="20px"
        />
      </CenterAlign>
    ),
  },
  {
    Header: 'Intern',
    width: 60,
    show: mode !== modes.DRAFT && user.allow_internal_vacancies,
    sortable: false,
    Cell: ({ original }) =>
      original.is_internal_vacancy && (
        <CenterAlign>
          <Tooltip
            target={`__internal__${original.external_id}`}
            delay={{ show: 200 }}
          >
            Interne vacature
          </Tooltip>
          <Icon
            id={`__internal__${original.external_id}`}
            icon="check"
            height="20px"
          />
        </CenterAlign>
      ),
  },
  {
    Header: 'Acties',
    headerClassName: 'actions_header',
    className: 'actions',
    width: 80,
    sortable: false,
    Cell: ({ original }) => {
      switch (mode) {
        case modes.DRAFT:
          return (
            <ActionButtons
              onEdit={
                !original.is_template || user.allow_edit_templates
                  ? () => history.push(`/drafts/edit/${original.id}`)
                  : null
              }
              onDuplicate={() =>
                history.push(`/drafts/duplicate?draft=${original.id}`)
              }
            />
          )
        case modes.PUBLISHED:
          return (
            <ActionButtons
              onEdit={
                user.allow_publish_vacancies
                  ? () =>
                      history.push(`/vacancies/edit/${original.external_id}`)
                  : null
              }
              onDuplicate={() =>
                history.push(
                  `/drafts/duplicate?vacancy=${original.external_id}`,
                )
              }
            />
          )
        case modes.ARCHIVED:
          return (
            <ActionButtons
              onDuplicate={() =>
                history.push(
                  `/drafts/duplicate?vacancy=${original.external_id}`,
                )
              }
            />
          )
        default:
          return
      }
    },
  },
]

class VacanciesTable extends React.Component {
  render() {
    return (
      <StyledTable
        manual
        sortable={true}
        resizable={false}
        multiSort={false}
        defaultPageSize={20}
        pageSizeOptions={[5, 10, 20, 50]}
        minRows={5}
        previousText={'Vorige'}
        nextText={'Volgende'}
        loadingText={'Aan het laden...'}
        noDataText={'Geen resultaat gevonden'}
        pageText={'Pagina'}
        ofText={'van'}
        rowsText={'regels'}
        {...this.props}
      >
        {(state, makeTable, instance) => {
          this.reactTable = instance
          return makeTable()
        }}
      </StyledTable>
    )
  }
}

class _VacancyView extends React.Component {
  constructor() {
    super()

    this.state = {
      error: null,
      data: [],
      loading: false,
      mode: window.location.hash.replace('#', '') || modes.DRAFT,
      pages: -1,
      pageSize: 50,
      sorted: [{ id: 'title', desc: false }],
      filtered: {},
      search: '',
    }

    this.refresh = this.refresh.bind(this)
    this.setMode = this.setMode.bind(this)
    this.setFilter = this.setFilter.bind(this)
    this.setSearch = this.setSearch.bind(this)
    this.loadData = this.loadData.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.organisation_id !== prevProps.organisation_id) {
      this.setState({
        filtered: {
          ...this.state.filtered,
          organisation: this.props.organisation_id,
        },
      })
    }
  }

  refresh() {
    // Simply refresh the table data with the same settings
    this.loadData(
      this.state.page,
      this.state.pageSize,
      this.state.mode,
      this.state.sorted,
      this.state.filtered,
      this.state.search,
    )
  }

  setMode(mode) {
    // Change the table mode, go to the first page, keep ordering and page size.
    this.loadData(
      0,
      this.state.pageSize,
      mode,
      this.state.sorted,
      this.state.filtered,
      this.state.search,
    )
    window.location.hash = mode
    if (this.table.reactTable) {
      this.table.reactTable.state.page = 0
    }
  }

  setFilter(filter) {
    // Filter the data, go to the first page, keep ordering and page size.
    this.loadData(
      0,
      this.state.pageSize,
      this.state.mode,
      this.state.sorted,
      {
        ...this.state.filtered,
        ...filter,
      },
      this.state.search,
    )
    if (this.table.reactTable) {
      this.table.reactTable.state.page = 0
    }
  }

  setSearch(search) {
    // Search the data, go to the first page, keep ordering and page size.
    this.loadData(
      0,
      this.state.pageSize,
      this.state.mode,
      this.state.sorted,
      this.state.filtered,
      search,
    )
    if (getSafe(this, 'table.reactTable')) {
      this.table.reactTable.state.page = 0
    }
  }

  loadData(page, pageSize, mode, sorted, filtered, search) {
    // page is 0-indexed page
    let endpoint, is_active
    const params = {
      page_size: pageSize,
    }
    // Set default ordering
    // The fallback is a dummy. Using an id that does not exist disables the sorting (keeping the ordering from the API)
    sorted = sorted && sorted.length ? sorted : [{ id: 'no_order', desc: true }]

    let ordering = sorted[0].id

    switch (mode) {
      case modes.DRAFT:
        endpoint = api.listDraftVacancies
        filtered = {
          ...filtered,
          data__organisation: getSafe(filtered, 'organisation'),
        }
        break
      case modes.PUBLISHED:
        is_active = 'active_scheduled'
        endpoint = api.listPublishedVacancies
        break
      case modes.ARCHIVED:
        is_active = false
        endpoint = api.listPublishedVacancies
        break
      default:
        endpoint = api.listDraftVacancies
    }
    ordering = sorted[0].desc ? `-${ordering}` : ordering

    this.setState({ error: null, loading: true })

    // only keep the param if it has a significant value
    let cleanParams = filterObject(
      {
        page: page + 1,
        ordering: ordering,
        search,
        ...params,
        ...filtered,
      },
      (value, key) => value,
    )
    // always keep is_active because it's a boolean
    cleanParams = {
      ...cleanParams,
      is_active,
    }

    endpoint({
      params: cleanParams,
    })
      .then((results) => {
        this.setState({
          error: null,
          loading: false,
          data: results.data.results,
          pages: Math.ceil(results.data.count / pageSize),
          page,
          pageSize,
          mode,
          sorted,
          filtered,
          search,
        })
      })
      .catch((error) => {
        this.setState({
          error,
          loading: false,
          data: [],
          pages: 0,
          page,
          pageSize,
          mode,
          sorted,
          filtered,
          search,
        })
      })
  }

  render() {
    return (
      <React.Fragment>
        {this.state.error && (
          <SelfDismissingAlert>
            <Alert color="danger">{this.state.error.toString()}</Alert>
          </SelfDismissingAlert>
        )}
        {getSafe(this.props, 'location.state.message') && (
          <SelfDismissingAlert>
            <Alert color="success">
              {this.props.location.state.message}
              {this.props.location.state.short_url && (
                <ExternalIdLink
                  href={this.props.location.state.short_url}
                  target="_blank"
                >
                  Bekijk de vacature
                </ExternalIdLink>
              )}
            </Alert>
          </SelfDismissingAlert>
        )}
        <Row>
          <Col xs={3}>
            <h4>Vacatures</h4>
          </Col>
          <Col xs={9}>
            <Container>
              {' '}
              {/* cancel negative margin from row */}
              <Row className="justify-content-between">
                <ButtonGroup className="overview-switch">
                  <IconButton
                    color="light"
                    label="Concept"
                    icon="draft"
                    active={this.state.mode === modes.DRAFT}
                    onClick={this.setMode.bind(this, modes.DRAFT)}
                    className="drafts"
                  />
                  <IconButton
                    color="light"
                    label="Gepubliceerd"
                    icon="published"
                    active={this.state.mode === modes.PUBLISHED}
                    onClick={this.setMode.bind(this, modes.PUBLISHED)}
                    className="vacancies"
                  />
                  <IconButton
                    color="light"
                    label="Gearchiveerd"
                    icon="archive"
                    active={this.state.mode === modes.ARCHIVED}
                    onClick={this.setMode.bind(this, modes.ARCHIVED)}
                    className="archive"
                  />
                </ButtonGroup>

                <div>
                  <Button tag={NavLink} color="secondary" to="/drafts/create">
                    Nieuwe (lege) vacature
                  </Button>
                </div>
              </Row>
            </Container>
          </Col>
        </Row>
        {this.state.mode !== modes.DRAFT && (
          <Row>
            <Col xs={12} className="d-flex">
              <Container>
                <Row className="d-flex flex-nowrap">
                  <SearchInput onSearch={(value) => this.setSearch(value)} />
                </Row>
              </Container>
            </Col>
          </Row>
        )}
        {this.props.user.is_at_user && (
          <Row>
            <Label xs={12} for="organisation_filter">
              Organisatie
            </Label>
            <Col xs={4} className="d-flex">
              <FilterDropdown
                getList={api.getOrganisationsList}
                onChange={(value) => this.setFilter({ organisation: value })}
              />
            </Col>
            <Col xs={9} />
          </Row>
        )}
        <Row>
          <Col sm="12">
            <VacanciesTable
              data={this.state.data}
              loading={this.state.loading}
              columns={getColumns(
                this.state.mode,
                this.refresh,
                this.props.history,
                this.props.user,
              )}
              pages={this.state.pages}
              onFetchData={(tableState) =>
                this.loadData(
                  tableState.page,
                  tableState.pageSize,
                  this.state.mode, // custom state prop
                  tableState.sorted,
                  this.state.filtered, //custom state prop
                  this.state.search, //custom state prop
                )
              }
              ref={(r) => (this.table = r)}
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    message: getSafe(state, 'location.state.message'),
    organisation_id: getSafe(state, 'auth.me.organisation_id'),
    user: getSafe(state, 'auth.me'),
  }
}

export default withRouter(connect(mapStateToProps)(_VacancyView))
