import { throttle, debounce } from 'lodash'
import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import styled from 'styled-components'

const StyledStickyNav = styled(Nav)`
  & .nav-item {
    hyphens: auto;
  }

  &.fixed {
    position: fixed;
    top: 40px;
  }

  &.stick_to_bottom {
    position: absolute;
    bottom: 0;
  }
`

export default class StickySideNav extends React.Component {
  constructor(props) {
    super(props)

    this.handleScroll = this.handleScroll.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.handleStickiness = throttle(this.handleStickiness.bind(this), 150)
    this.handleFormStep = debounce(this.handleFormStep.bind(this), 150)

    this.state = {
      step: this.props.navitems.length ? this.props.navitems[0].href : null,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
  }

  componentDidUpdate(prevProps) {
    if (this.state.step === null) {
      this.setState({
        step: this.props.navitems.length ? this.props.navitems[0].href : null,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
  }

  handleClick(target, evt) {
    evt.preventDefault()
    this.setState({ step: target })
    const cardNode = document.getElementById(target)
    if (cardNode) {
      document.getElementById(target).scrollIntoView({ behavior: 'smooth' })
    }
  }

  handleScroll() {
    this.handleStickiness()
    this.handleFormStep()
  }

  handleResize() {
    this.handleStickiness()
    this.handleFormStep()
  }

  handleStickiness() {
    const vacancyFormNode = document.getElementById('vacancy-form-wrapper')
    const navNode = document.getElementById('vertical-side-nav')

    const { bottom: formBottom, top: formTop } =
      vacancyFormNode.getBoundingClientRect()
    const { height: navHeight } = navNode.getBoundingClientRect()

    if (formTop > 40) {
      navNode.classList.remove('fixed')
      navNode.classList.remove('stick_to_bottom')
    } else if (formBottom < navHeight) {
      navNode.classList.add('stick_to_bottom')
      navNode.classList.remove('fixed')
    } else {
      navNode.classList.add('fixed')
      navNode.classList.remove('stick_to_bottom')
    }

    // Elements with position: fixed are no longer sized relative to the parent
    // This means they will become as wide as the viewport. To prevent this
    // set the width to that of the parent (minus padding).
    navNode.style.width = navNode.parentNode.clientWidth - 30 + 'px'
  }

  handleFormStep() {
    const { navitems } = this.props
    let activeStep = this.state.step
    const visibleItems = []
    for (let i = 0; i < navitems.length; i++) {
      const item = navitems[i]
      const cardNode = document.getElementById(item.href)
      if (cardNode) {
        const { top: cardTop } = cardNode.getBoundingClientRect()
        const roundedTop = Math.round(cardTop)
        if (roundedTop >= 0 && roundedTop < window.innerHeight * 0.45) {
          // A step is visible if the top of the card is on-screen
          visibleItems.push(item)
        }
      }
    }
    if (visibleItems.length) {
      // The activeStep is probably the first visible item
      activeStep = visibleItems[0].href
      for (let i = 0; i < visibleItems.length; i++) {
        if (visibleItems[i].href === this.state.step) {
          // Current item is still visible, use that instead
          // this fixes the issue where clicking on the last item
          // wouldn't highlight that item on larger screens
          activeStep = visibleItems[i].href
        }
      }
    }
    this.setState({ step: activeStep })
  }

  render() {
    const { navitems } = this.props
    return (
      <StyledStickyNav pills vertical id="vertical-side-nav">
        {navitems.map((child) => (
          <NavItem key={child.label + child.href}>
            <NavLink
              active={this.state.step === child.href}
              tag="a"
              href={'#' + child.href}
              onClick={this.handleClick.bind(this, child.href)}
            >
              {child.label}{' '}
              {child.errors > 0 && (
                <span className="badge badge-pill badge-danger">
                  {child.errors}
                </span>
              )}
            </NavLink>
          </NavItem>
        ))}
      </StyledStickyNav>
    )
  }
}
