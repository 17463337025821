import React from 'react'
import { Col, FormGroup, Label, Row } from 'reactstrap'
import { Field } from 'redux-form'

import IconButton from './IconButton'
import ToolTip from './Tooltip'
import { renderInput, renderSelect } from './renderers'

const OriginalQuery = (props) => (
  <span className="original-query" style={{ fontSize: '0.75em' }}>
    <strong>Oorspronkelijk: </strong>
    {props.query}
  </span>
)

export default class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props)

    this.triggerAutocomplete = this.triggerAutocomplete.bind(this)
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.setStateFromResult = this.setStateFromResult.bind(this)

    this.state = {
      address: '',
      postalcode: '',
      country_code: '',
      city: '',
      originalAddress: '',
      originalPostalcode: '',
      originalCountry_code: '',
      originalCity: '',
    }

    if (typeof window === 'undefined') {
      return
    }

    // grab our googleMaps obj
    // key for local: AIzaSyAHUH3_zI9c-otF2hCsYx_1PMFYU8F2Hqw
    var googleMaps =
      this.props.googleMaps ||
      (window.google && // eslint-disable-line no-extra-parens
        window.google.maps) ||
      this.googleMaps

    if (!googleMaps) {
      console.error(
        // eslint-disable-line no-console
        'Google map api was not found in the page.',
      )
      return
    }
    // now grab the services we need
    this.googleMaps = googleMaps
    this.geocoder = new googleMaps.Geocoder()
  }

  setStateFromResult = (result) => {
    let location = {}
    // Map state to result
    // Update the actual values of the form fields
    result.address_components.forEach((component) => {
      // Convert the address_components list of dicts to a flat dict.
      let component_types = component['types']
      let component_value = component['short_name']
      let key = ''
      if (component_types.includes('street_number')) {
        location['streetnumber'] = component_value
      } else if (component_types.includes('route')) {
        key = 'address'
      } else if (component_types.includes('locality')) {
        key = 'city'
      } else if (component_types.includes('postal_code')) {
        key = 'postalcode'
      } else if (component_types.includes('country')) {
        key = 'country_code'
      }
      if (key !== '') {
        location[key] = component_value
        this.props.changeFieldValue(key, component_value)
      }
    })

    // Merge street number and address into the address field
    if (location.streetnumber && location.address) {
      let address = location.address + ' ' + location.streetnumber
      location.address = address
      this.props.changeFieldValue('address', address)
    }
    delete location.streetnumber

    // Actually update the state
    this.setState(location)
  }

  triggerAutocomplete(evt) {
    let {
      originalAddress,
      originalCity,
      originalCountry_code,
      originalPostalcode,
      ...address_parts
    } = this.state

    // Keep query params to show original states
    this.setState({
      originalAddress: address_parts.address,
      originalCity: address_parts.city,
      originalPostalcode: address_parts.postalcode,
      originalCountry_code: address_parts.country_code,
    })

    let query = Object.values(address_parts).reduce((stash, item) => {
      return stash + ' ' + item
    })

    this.geocoder.geocode({ address: query }, (results, status) => {
      let first_result = results[0]
      if (first_result) {
        this.setStateFromResult(first_result)
      }
    })
  }

  handleFieldChange(evt, value) {
    let key,
      target = evt.currentTarget
    if (target && target.name) {
      key = [target.name]
    } else {
      key = 'country_code'
    }
    this.setState({
      [key]: value,
    })
  }

  render() {
    let { country_choices, helpText } = this.props

    return (
      <React.Fragment>
        <FormGroup>
          <Row>
            <Col>
              <Label for="address">Adres</Label>
              {helpText && <ToolTip>{helpText}</ToolTip>}
              <Field
                onChange={this.handleFieldChange}
                name="address"
                placeholder="Adres"
                component={renderInput}
                value={this.state.address}
              />
              {this.state.originalAddress && (
                <OriginalQuery query={this.state.originalAddress} />
              )}
            </Col>
            <Col>
              <Label for="postalcode">Postcode</Label>
              <Field
                onChange={this.handleFieldChange}
                name="postalcode"
                component={renderInput}
                placeholder="Postcode"
                value={this.state.postalcode}
              />
              {this.state.originalPostalcode && (
                <OriginalQuery query={this.state.originalPostalcode} />
              )}
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col>
              <Label for="city">Standplaats</Label>
              <Field
                onChange={this.handleFieldChange}
                name="city"
                component={renderInput}
                placeholder="Stad"
                value={this.state.city}
              />
              {this.state.originalCity && (
                <OriginalQuery query={this.state.originalCity} />
              )}
            </Col>
            <Col>
              <Label for="country_code">Land</Label>
              <Field
                onChange={this.handleFieldChange.bind('country_code')}
                name="country_code"
                type="select"
                component={renderSelect}
                options={country_choices}
                labelKey="name"
                valueKey="code"
                value={this.state.country_code}
              />
              {this.state.originalCountry_code && (
                <OriginalQuery
                  query={
                    country_choices.filter(
                      (c) => c.code === this.state.originalCountry_code,
                    )[0].name
                  }
                />
              )}
            </Col>
          </Row>
        </FormGroup>
        <IconButton
          label="Check"
          icon="map"
          outline
          color="info"
          onClick={this.triggerAutocomplete}
        />
        <ToolTip>Check of de locatie klopt in Google Maps</ToolTip>
      </React.Fragment>
    )
  }
}
