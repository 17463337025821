import React from 'react'
import FormCard from '../FormCard'

function toFieldList(submitErrors) {
  // Turn the submitErrors object into a flat list of fields with errors
  const errors = Object.keys(submitErrors)
  if (submitErrors.translations) {
    errors.push(...Object.keys(submitErrors.translations[0]))
  }
  return errors
}

function getErrorCount(fields, submitErrors) {
  const errors = toFieldList(submitErrors)
  // Number of errors in this formCard
  return errors.filter((field) => fields.includes(field)).length
}

/**
 * Wrap a component with a FormCard and register it with the
 * VacancyForm navigation.
 *
 * @param WrappedComponent
 *  Content component of the FormCard
 * @param label
 *  Title to show in the nav bar and on top of the card
 * @param htmlId
 *  HTML ID, used for anchor links
 * @param fields
 *  List of field names in this card
 */
export default function withFormCard(
  WrappedComponent,
  { label, htmlId, fields },
) {
  class WithFormCard extends React.Component {
    constructor() {
      super()
      if (!label || !htmlId || !fields) {
        throw Error('Please define label, htmlId and fields')
      }
    }

    componentDidMount() {
      const { addToNav, submitErrors } = this.props
      addToNav(htmlId, {
        label: label,
        href: htmlId,
        errors: getErrorCount(fields, submitErrors),
      })
    }

    componentDidUpdate(prevProps) {
      const { updateNavErrorCount, submitErrors } = this.props
      if (prevProps.submitErrors !== submitErrors) {
        updateNavErrorCount(htmlId, getErrorCount(fields, submitErrors))
      }
    }

    render() {
      return (
        <FormCard title={label} id={htmlId}>
          <WrappedComponent {...this.props} />
        </FormCard>
      )
    }
  }

  WithFormCard.displayName = `WithFormCard(${getDisplayName(WrappedComponent)})`
  return WithFormCard
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}
