import React from 'react'
import { CustomInput } from 'reactstrap'
import { Field } from 'redux-form'

export const renderRadioSelect = ({ choice, input }) => {
  return (
    <CustomInput
      type="radio"
      id={choice.label}
      {...input}
      label={choice.label}
      value={choice.id}
      checked={input.value.toString() === choice.id.toString()}
    />
  )
}

export default function RadioSelect({ choiceList, name }) {
  return choiceList.map((choice) => (
    <Field
      key={choice.id}
      name={name}
      choice={choice}
      component={renderRadioSelect}
    />
  ))
}
