import React from 'react'
import styled from 'styled-components'
import FormField from './FormField'
import SwitchCheckBox from './SwitchCheckBox'

const StyledConditionalFieldsWrapper = styled.div`
  &[data-isopen='true'] {
    margin-top: 1rem;
  }
`

export default class CheckboxShowField extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      open: this.props.isOpen,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.setState({
        open: this.props.isOpen,
      })
    }
  }

  toggle() {
    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    return (
      <React.Fragment>
        <FormField
          inline
          component={SwitchCheckBox}
          onClick={this.toggle}
          onChange={this.toggle}
          isChecked={this.state.open}
          {...this.props}
        />
        <StyledConditionalFieldsWrapper
          data-isopen={this.state.open.toString()}
        >
          {this.state.open && this.props.children}
        </StyledConditionalFieldsWrapper>
      </React.Fragment>
    )
  }
}
