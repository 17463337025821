import { addDays, isValidDate } from './dateUtils'

export function required(value) {
  return value || typeof value === 'number'
    ? undefined
    : 'Dit veld mag niet leeg zijn'
}

export function validatePublicationDates(value, formValues, formProps, name) {
  const dateValue = new Date(value)

  if (!isValidDate(dateValue)) {
    return 'Ongeldige datum'
  }

  const startDate = formValues.start_date && new Date(formValues.start_date)
  const endDate = formValues.end_date && new Date(formValues.end_date)

  if (!isValidDate(startDate) || !isValidDate(endDate)) {
    // The rest of the validation depends on both dates being valid
    return
  }

  if (name === 'end_date') {
    if (endDate < startDate) {
      return 'De einddatum moet na de startdatum liggen'
    } else if (endDate > addDays(startDate, 180)) {
      return 'De publicatie mag niet langer dan 180 dagen zijn'
    }
  }

  // Removes the time part and sets it to 00:00
  const today = new Date(new Date().toDateString())
  if (dateValue < today) {
    return 'Deze datum mag niet in het verleden liggen'
  }
}
