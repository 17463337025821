import React from 'react'
import { Alert } from 'reactstrap'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hasError: false,
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true })
  }

  render() {
    return (
      <React.Fragment>
        {this.state.hasError && <Alert color="danger">Er ging iets mis</Alert>}
        {this.props.children}
      </React.Fragment>
    )
  }
}
