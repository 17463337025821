import React from 'react'
import { Col, FormGroup, Label } from 'reactstrap'
import { Field } from 'redux-form'

import OptionalField from './OptionalField'
import ToolTip from './Tooltip'

export default function FormField({
  component,
  helpText,
  inline = false,
  label,
  name,
  optional = false,
  isOpen = false,
  ...fieldProps
}) {
  const field = <Field component={component} name={name} {...fieldProps} />
  return (
    <FormGroup row>
      {(label || helpText) && (
        <Col sm={inline ? 4 : 12}>
          <Label>{label}</Label>
          {helpText && <ToolTip>{helpText}</ToolTip>}
        </Col>
      )}
      <Col sm={inline ? 8 : 12}>
        {optional ? (
          <OptionalField isOpen={isOpen}>{field}</OptionalField>
        ) : (
          field
        )}
      </Col>
    </FormGroup>
  )
}
